
import logo from '@/components/layout/assets/topbarLogo.png';
import { FORMATS_LAYOUT } from '@/features/campaigns/domain/constants';
import { onMounted, ref, watch } from 'vue';
import { useGetPublicPreviewFormats } from '@/features/public-preview/application/useGetPublicPreviewFormats';
import { useFetchAssets } from '@/features/media/application/useFetchAssets';
import { useFetchDevices } from '@/features/campaigns/application/device/useFetchDevices';
import { useFetchContracts } from '@/features/campaigns/application/contract/useFetchContracts';
import { useCreateFormatPreviews } from '@/features/public-preview/application/useCreateFormatPreviews';
import FormatPreviewComponent from '@/features/campaigns/presentation/motivePreview/formatPreview.vue';
import AppTxt from '@/components/typography/typography.vue';
import { Icon as AppIcon } from '@/components/icon';
import { useRoute } from 'vue-router';
import { Button as AppButton } from '@/components/button';
import { t } from '@/services/i18n';
import { useMotiveCampaignUrl } from '@/features/public-preview/application/useMotiveCampaignUrl';
import { DEFAULT_SALES_SLIDE_INDEX } from '@/features/campaigns/domain/valueObjects/slide';
import { FormatPreview } from '@/features/campaigns/domain/format/formatPreview';

const translationBaseScope = 'publicPreviews';

export default {
  components: { AppButton, AppIcon, FormatPreviewComponent, AppTxt },
  setup() {
    const DEFAULT_FORMAT = '300x250';

    const route = useRoute();

    const { call } = useGetPublicPreviewFormats();

    useFetchAssets({});
    useFetchDevices({});
    useFetchContracts({});

    const {
      motiveUrlToClipboard,
      campaignUrlToClipboard,
      currentUrlToClipboard,
    } = useMotiveCampaignUrl();

    const replay = ref(false);

    const replayFormats = () => {
      replay.value = true;
      replay.value = false;
    };

    const formatPreview = useCreateFormatPreviews(replay);

    const formatFilterOptions = FORMATS_LAYOUT.reduce(
      (previous, formats) => [
        ...previous,
        ...formats.map(format => ({
          value: `${format.width}x${format.height}`,
          label: `${format.width}x${format.height}`,
        })),
      ],
      [] as { value: string; label: string }[],
    );

    const formatFromUrl = route.query.format as string | undefined;

    const selectedFormat = ref(formatFromUrl || DEFAULT_FORMAT);
    watch(
      selectedFormat,
      () => {
        call(selectedFormat.value);
      },
      {
        immediate: true,
      },
    );

    const hasClicked = ref(false);

    window.addEventListener('click', () => {
      hasClicked.value = true;
    });

    const selectedCampaignId = route.query.campaignId as string | undefined;
    const selectedMotiveId = route.query.motiveId as string | undefined;

    const scrollToElementById = () => {
      const element = document.getElementById(selectedMotiveId || '');

      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    };

    onMounted(() => {
      scrollToElementById();
    });

    const generateClickURLTooltipContent = (formatPreview?: FormatPreview) => {
      if (!formatPreview) return;

      const { slides } = formatPreview;

      const urls = [];

      for (const slideElement of slides.elements) {
        urls.push(slideElement.content.map(c => c.clickUrl));
      }

      const flattened = urls.flat();

      if (flattened.length > 1) return flattened[1];

      if (flattened.length > 0) return flattened[0];
    };

    return {
      generateClickURLTooltipContent,
      logo,
      t,
      translationBaseScope,
      formatFilterOptions,
      selectedFormat,
      previews: formatPreview,
      campaignUrlToClipboard,
      motiveUrlToClipboard,
      currentUrlToClipboard,
      selectedCampaignId,
      selectedMotiveId,
      hasClicked,
      replayFormats,
      DEFAULT_SALES_SLIDE_INDEX,
    };
  },
};
