import { AxiosService, Failure, HttpService } from '@/core';
import { Either, Left, Right } from 'purify-ts';
import { PublicPreview } from '@/features/public-preview/store/store';
import { Motive } from '@/features/campaigns/domain/motive/motive';
import { MotiveDTO } from '@/features/campaigns/infrastructure/motive_dto';

const endpoints = {
  authentication: 'auth/public-preview/login',
  getFormats: (name: string) =>
    `campaign/public-preview/format/raw?name=${name}`,
  motivesByIds: (ids: string[]) => `campaign/public-preview/motive?ids=${ids}`,
  getAssets: 'media/public-preview/assets',
};

export class PublicPreviewAuthFailure extends Failure {
  constructor(error: any, status?: number) {
    super('Login failure', error, status);
  }
}

export class PublicPreviewHttpFacade {
  private readonly httpService: HttpService;

  constructor() {
    this.httpService = new AxiosService();
  }

  async login(params: {
    password: string;
  }): Promise<Either<PublicPreviewAuthFailure, undefined>> {
    try {
      await this.httpService.post(endpoints.authentication, params);
      return Right(undefined);
    } catch (e) {
      return Left(new PublicPreviewAuthFailure(e, e.code));
    }
  }

  async getPublicPreviews(params: {
    name: string;
  }): Promise<Either<{ authError: boolean }, PublicPreview[]>> {
    try {
      const result = await this.httpService.get(
        endpoints.getFormats(params.name),
      );

      return Right(result.data as PublicPreview[]);
    } catch (e) {
      if (e.code === 401) {
        return Left({ authError: true });
      }
      return Left({ authError: false });
    }
  }

  async getMotivesByIds(ids: string[]): Promise<Either<undefined, Motive[]>> {
    try {
      const result = await this.httpService.get(endpoints.motivesByIds(ids));
      const motives = result.data.map((motive: Record<string, any>) =>
        MotiveDTO.toDomain(motive),
      );

      return Right(motives);
    } catch (e) {
      return Left(undefined);
    }
  }
}
