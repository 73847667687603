
import ConfirmDeletionModal from '@/components/confirmDeletionModal/confirmDeletionModal.vue';
import { TemplateNames } from '@/components/table';
import { DateWrapper } from '@/core';
import { useDeleteMotive } from '@/features/campaigns/application/motive/actions/useDeleteMotive';
import { t } from '@/services/i18n';
import { generateTooltipContent } from '@/utils/generateTooltipContent';
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import {
  DateCellWithEndingDays,
  SelectAllHeaderCell,
  SortableHeaderCell,
  StatusCell,
  TextWithDescriptionCell,
} from '../../../../components/table/cellTemplates';
import { useGetCampaignById } from '../../application/campaign/actions/useGetCampaignById';
import { campaignDetailsTableHeaders } from '../../application/campaign/details/campaignDetailsTableHeaders';
import { useGetCampaignDetailsRows } from '../../application/campaign/list/useGetCampaignDetailsRows';
import { useFetchMotivesForCampaignId } from '../../application/motive/actions/useFetchMotivesForCampaignId';
import CampaignDetailDropdownCell from '../campaignDetails/campaignDetailsDropdownCell.vue';
import CampaignDetailsEmptyState from '../campaignDetails/campaignDetailsEmptyState.vue';
import MotiveHeaderCell from '../campaignDetails/motiveHeaderCell.vue';
import { campaignsRouteNames } from '../routes/campaignsRouteNames';
import BulkCreateMotiveModal from './bulkCreateMotiveModal/bulkCreateMotiveModal.vue';
import CreateMotiveModal from './createMotiveModal/createMotiveModal.vue';
import DuplicateMotiveModal from './duplicateMotiveModal.vue';
import EditCampaignModal from './editCampaignModal.vue';
import MotiveGroupCell from './motiveGroupCell.vue';

const CAMPAIGNS_DETAILS_TABLE_PAGE_SIZE = 10;

export default {
  components: {
    ConfirmDeletionModal,
    DateCellWithEndingDays,
    TextWithDescriptionCell,
    SortableHeaderCell,
    SelectAllHeaderCell,
    MotiveHeaderCell,
    CampaignDetailDropdownCell,
    CampaignDetailsEmptyState,
    EditCampaignModal,
    CreateMotiveModal,
    StatusCell,
    DuplicateMotiveModal,
    BulkCreateMotiveModal,
    MotiveGroupCell,
  },
  methods: {
    generateTooltipContent,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { campaign } = useGetCampaignById(route.query.id as string);
    const campaignName = computed(() => campaign?.value?.name);

    useFetchMotivesForCampaignId({ campaignId: campaign.value.id });
    const rows = useGetCampaignDetailsRows(campaign.value.id);
    const searchedValue = ref('');

    const isEditCampaignModalOpen = ref(false);
    const setIsEditCampaignModalOpen = (value: boolean) => {
      isEditCampaignModalOpen.value = value;
    };

    const isCreateMotiveModalOpen = ref(false);
    const setIsCreateMotiveModalOpen = (value: boolean) => {
      isCreateMotiveModalOpen.value = value;
    };

    const onBulkEdit = () => {
      console.log('Bulk edit');
    };

    const selectedRows = ref<Record<string, boolean>>({});
    const onCheckboxSelected = (rowId: string, isSelected: boolean) => {
      selectedRows.value[rowId] = isSelected;
    };
    const onSelectAll = (params: {
      selectAll: boolean;
      rowsToSelect: { id: string }[];
    }) => {
      if (!params.selectAll) {
        selectedRows.value = {};
      } else {
        selectedRows.value = Object.fromEntries(
          params.rowsToSelect.map(row => [row.id, true]),
        );
      }
    };
    const formattedRows = computed(() =>
      rows.value
        .map((row: { id: string; startDate: DateWrapper }) => {
          return {
            ...row,
            isSelected: !!selectedRows.value[row.id],
          };
        })
        .sort((a, b) => {
          const time1 = a.startDate.getValue()?.getTime();
          const time2 = b.startDate.getValue()?.getTime();
          if (!time1 || !time2) return 0;
          if (time1 < time2) return 1;
          if (time1 > time2) return -1;
          return 0;
        }),
    );

    const showBulkEdit = computed(
      () => formattedRows.value.filter(row => row.isSelected).length > 1,
    );

    const returnToCampaignOverview = () => {
      router.push({ name: campaignsRouteNames.campaignList });
    };
    const onRowClick = (id: string) => {
      router.push({ name: campaignsRouteNames.motiveDetails, query: { id } });
    };

    const currentlyDeletedMotiveId = ref('');

    const { call } = useDeleteMotive();
    const onDeleteMotive = (motiveId: string) => {
      currentlyDeletedMotiveId.value = motiveId;
    };
    const deleteMotive = async () => {
      await call(currentlyDeletedMotiveId.value);
      currentlyDeletedMotiveId.value = '';
    };

    const resetCurrentlyDeletedMotiveId = () => {
      currentlyDeletedMotiveId.value = '';
    };

    const currentlyDuplicatedMotiveId = ref('');
    const onDuplicateMotive = (motiveId: string) => {
      currentlyDuplicatedMotiveId.value = motiveId;
    };
    const resetCurrentlyDuplicatedMotiveId = () => {
      currentlyDuplicatedMotiveId.value = '';
    };

    const isBulkCreateMotiveModalOpen = ref(false);
    const setIsBulkCreateMotiveModalOpen = (value: boolean) => {
      isBulkCreateMotiveModalOpen.value = value;
    };

    return {
      t,
      name: campaignName,
      campaign,
      searchedValue,
      returnToCampaignOverview,
      TemplateNames,
      headers: campaignDetailsTableHeaders,
      rows: formattedRows,
      onCheckboxSelected,
      onSelectAll,
      selectedRows,
      CAMPAIGNS_DETAILS_TABLE_PAGE_SIZE,
      showBulkEdit,
      onBulkEdit,
      isEditCampaignModalOpen,
      setIsEditCampaignModalOpen,
      isCreateMotiveModalOpen,
      setIsCreateMotiveModalOpen,
      onRowClick,
      currentlyDeletedMotiveId,
      onDeleteMotive,
      resetCurrentlyDeletedMotiveId,
      onDuplicateMotive,
      currentlyDuplicatedMotiveId,
      resetCurrentlyDuplicatedMotiveId,
      isBulkCreateMotiveModalOpen,
      setIsBulkCreateMotiveModalOpen,
      deleteMotive,
    };
  },
};
