<template>
  <div :class="`${rightSpacing ? 'pr-4' : ''} mb-4.5`">
    <app-txt v-show="showFormatName" variant="small" class="text-gray-500">{{
      `${width} x ${height}`
    }}</app-txt>
    <div class="relative" :style="`width: ${width}px; height: ${height}px`">
      <iframe
        ref="iframeRef"
        :id="iframeId"
        :src="templatesBucketUrl"
        v-if="!!dataObj"
        width="100%"
        height="100%"
      />
      <div
        v-if="!isInteractive"
        @click="onClick"
        class="absolute top-0 left-0"
        :style="`width: ${width}px; height: ${height}px`"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, nextTick, ref, watch } from 'vue';
import { getEnvVariable } from '@/utils/getEnvVariable';
import { deepCopyJson } from '@/core/helpers';

const clickEvent = 'click';

export default defineComponent({
  props: {
    width: { type: Number, required: true },
    height: { type: Number, required: true },
    version: { type: String, required: true },
    dataObj: { type: Object },
    isInteractive: { type: Boolean, default: true },
    shouldReplay: { type: Boolean, default: false },
    areVisualsDraggable: { type: Boolean, default: false },
    motiveId: { type: String, required: false },
    showFormatName: { type: Boolean, default: true },
    rightSpacing: { type: Boolean, default: true },
  },
  emits: [clickEvent],
  setup(props, context) {
    const templatesBucketUrl = getEnvVariable(
      'VUE_APP_AWS_TEMPLATES_BUCKET_BASE_URL',
    );

    const motiveId = props.motiveId ?? '';

    const iframeId = `iframe-format-${props.width}x${props.height}` + motiveId;

    const iframeRef = ref<HTMLIFrameElement>();

    const injectData = (iframe: HTMLIFrameElement) => {
      if (iframe && iframe.tagName === 'IFRAME') {
        const formatNameMessage = {
          type: 'updateFormat',
          content: `${props.width}x${props.height}`,
        };
        const dataMessage = {
          type: 'updateData',
          content: deepCopyJson(props.dataObj?.slides),
        };
        iframe.contentWindow?.postMessage(formatNameMessage, '*');
        iframe.contentWindow?.postMessage(dataMessage, '*');
      }
    };

    const updateIframe = () => {
      const iframe = document.getElementById(iframeId) as HTMLIFrameElement;

      if (iframe && iframe.tagName === 'IFRAME') {
        iframe.onload = () => injectData(iframe);
        injectData(iframe);
      }
    };

    const onClick = () => {
      if (!props.isInteractive) {
        context.emit(clickEvent);
      }
    };

    watch(
      () => props.dataObj,
      async () => {
        await nextTick();
        updateIframe();
      },
      {
        flush: 'post',
        immediate: true,
      },
    );

    return {
      onClick,
      iframeId,
      iframeRef,
      templatesBucketUrl,
      showName: props.showFormatName,
    };
  },
});
</script>
