<template>
  <div
    class="flex flex-col h-screen bg-black laptop-only pr-3 pl-3 overflow-y-auto"
  >
    <img :src="logo" alt="company logo" class="w-topbarLogoLaptopWidth m-2" />

    <header class="flex flex-row items-center justify-between">
      <div class="flex flex-row items-center gap-2">
        <AppTxt variant="h1" class="font-semibold text-white">{{
          t(`${translationBaseScope}.title`)
        }}</AppTxt>
        <button
          class="text-primary-700 border rounded-1 py-1.5 px-2"
          @click="currentUrlToClipboard"
        >
          {{ t(`${translationBaseScope}.sharePreviewButton`) }}
        </button>
      </div>
      <app-select
        class="w-[180px] bg-white rounded-1"
        :placeholder="t('formatFilterPlaceholder')"
        :options="formatFilterOptions"
        limit-dropdown-width
        v-model="selectedFormat"
      />
    </header>

    <app-button
      class="fixed bottom-5 right-5"
      @click="replayFormats"
      left-icon="replayWhite"
    >
      {{ t(`${translationBaseScope}.replayButton`) }}
    </app-button>

    <details
      class="flex flex-col bg-white mt-3 rounded-1"
      v-for="(campaign, index) in previews"
      :key="index"
      :open="
        selectedCampaignId === undefined ||
          selectedCampaignId === campaign.campaignId
      "
    >
      <summary
        class="flex flex-row content-center justify-between py-1 px-3 rounded-1 cursor-pointer border-b border-gray-200"
      >
        <div class="flex flex-row items-center gap-2">
          <AppTxt variant="medium">{{ campaign.campaignName }}</AppTxt>

          <div
            class="flex flex-row gap-0.5"
            @click.prevent="
              campaignUrlToClipboard(
                campaign.campaignId,
                selectedFormat,
                campaign.campaignName,
              )
            "
          >
            <app-icon alt="blue chain" name="blueChain" />
            <p class="text-primary text-small font-medium">
              {{ t(`${translationBaseScope}.shareCampaignButton`) }}
            </p>
          </div>
        </div>

        <app-icon alt="chevron down" name="chevronDown" class="icon" />
      </summary>

      <div class="flex flex-row flex-wrap px-2 pt-2">
        <div
          :class="{
            'flex flex-col mb-4 px-1 py-1.5': true,
            'border-primary-700 border-2 rounded-2 bg-primary-150':
              selectedMotiveId === motive.motiveId && !hasClicked,
          }"
          v-for="(motive, index) in campaign.motives"
          :key="index"
        >
          <FormatPreviewComponent
            v-tippy="{
              content: generateClickURLTooltipContent(motive?.formatPreview),
              theme: 'custom',
              placement: 'bottom-start',
            }"
            class="mb-0 pr-0"
            :id="motive.motiveId"
            :motive-id="motive.motiveId"
            :version="motive.templateVersion"
            :width="Number(motive.formatPreview.format.width)"
            :height="Number(motive.formatPreview.format.height)"
            :dataObj="motive.formatPreview"
            :show-format-name="false"
            :right-spacing="false"
          />

          <div class="flex flex-col">
            <div
              class="flex flex-col"
              :style="`max-width: ${motive.formatPreview.format.width}px`"
            >
              <div class="mb-1">
                <span class="text-tiny text-gray-600">{{
                  t(`${translationBaseScope}.motiveNameLabel`)
                }}</span>

                <p
                  class="font-medium line-clamp-3"
                  ref="reference"
                  v-tippy="{
                    content: motive.motiveName,
                    theme: 'custom',
                    placement: 'bottom-start',
                  }"
                >
                  {{ motive.motiveName }}
                </p>
              </div>

              <div class="mb-1">
                <span class="text-tiny text-gray-600">
                  {{ t(`${translationBaseScope}.motiveStartDateLabel`) }}
                </span>
                <p class="font-medium">{{ motive.motiveStartDate }}</p>
              </div>

              <div>
                <span class="text-tiny text-gray-600">
                  {{ t(`${translationBaseScope}.motiveEndDateLabel`) }}
                </span>
                <p class="font-medium">{{ motive.motiveEndDate }}</p>
              </div>

              <span
                v-show="Boolean(motive.daysRemaining)"
                class="text-orange-600 font-medium text-tiny"
              >
                {{
                  t(`${translationBaseScope}.motiveDaysRemainingLabel`, {
                    count: motive.daysRemaining,
                  })
                }}
              </span>
            </div>

            <div class="flex flex-row justify-self-auto gap-0.5 mt-2">
              <app-icon alt="blue chain" name="blueChain" />
              <p
                class="text-primary text-small font-medium cursor-pointer"
                @click="
                  motiveUrlToClipboard(
                    motive.motiveId,
                    campaign.campaignId,
                    selectedFormat,
                    motive?.motiveName,
                  )
                "
              >
                {{ t(`${translationBaseScope}.shareMotiveButton`) }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </details>
  </div>
</template>

<script lang="ts">
import logo from '@/components/layout/assets/topbarLogo.png';
import { FORMATS_LAYOUT } from '@/features/campaigns/domain/constants';
import { onMounted, ref, watch } from 'vue';
import { useGetPublicPreviewFormats } from '@/features/public-preview/application/useGetPublicPreviewFormats';
import { useFetchAssets } from '@/features/media/application/useFetchAssets';
import { useFetchDevices } from '@/features/campaigns/application/device/useFetchDevices';
import { useFetchContracts } from '@/features/campaigns/application/contract/useFetchContracts';
import { useCreateFormatPreviews } from '@/features/public-preview/application/useCreateFormatPreviews';
import FormatPreviewComponent from '@/features/campaigns/presentation/motivePreview/formatPreview.vue';
import AppTxt from '@/components/typography/typography.vue';
import { Icon as AppIcon } from '@/components/icon';
import { useRoute } from 'vue-router';
import { Button as AppButton } from '@/components/button';
import { t } from '@/services/i18n';
import { useMotiveCampaignUrl } from '@/features/public-preview/application/useMotiveCampaignUrl';
import { DEFAULT_SALES_SLIDE_INDEX } from '@/features/campaigns/domain/valueObjects/slide';
import { FormatPreview } from '@/features/campaigns/domain/format/formatPreview';

const translationBaseScope = 'publicPreviews';

export default {
  components: { AppButton, AppIcon, FormatPreviewComponent, AppTxt },
  setup() {
    const DEFAULT_FORMAT = '300x250';

    const route = useRoute();

    const { call } = useGetPublicPreviewFormats();

    useFetchAssets({});
    useFetchDevices({});
    useFetchContracts({});

    const {
      motiveUrlToClipboard,
      campaignUrlToClipboard,
      currentUrlToClipboard,
    } = useMotiveCampaignUrl();

    const replay = ref(false);

    const replayFormats = () => {
      replay.value = true;
      replay.value = false;
    };

    const formatPreview = useCreateFormatPreviews(replay);

    const formatFilterOptions = FORMATS_LAYOUT.reduce(
      (previous, formats) => [
        ...previous,
        ...formats.map(format => ({
          value: `${format.width}x${format.height}`,
          label: `${format.width}x${format.height}`,
        })),
      ],
      [] as { value: string; label: string }[],
    );

    const formatFromUrl = route.query.format as string | undefined;

    const selectedFormat = ref(formatFromUrl || DEFAULT_FORMAT);
    watch(
      selectedFormat,
      () => {
        call(selectedFormat.value);
      },
      {
        immediate: true,
      },
    );

    const hasClicked = ref(false);

    window.addEventListener('click', () => {
      hasClicked.value = true;
    });

    const selectedCampaignId = route.query.campaignId as string | undefined;
    const selectedMotiveId = route.query.motiveId as string | undefined;

    const scrollToElementById = () => {
      const element = document.getElementById(selectedMotiveId || '');

      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    };

    onMounted(() => {
      scrollToElementById();
    });

    const generateClickURLTooltipContent = (formatPreview?: FormatPreview) => {
      if (!formatPreview) return;

      const { slides } = formatPreview;

      const urls = [];

      for (const slideElement of slides.elements) {
        urls.push(slideElement.content.map(c => c.clickUrl));
      }

      const flattened = urls.flat();

      if (flattened.length > 1) return flattened[1];

      if (flattened.length > 0) return flattened[0];
    };

    return {
      generateClickURLTooltipContent,
      logo,
      t,
      translationBaseScope,
      formatFilterOptions,
      selectedFormat,
      previews: formatPreview,
      campaignUrlToClipboard,
      motiveUrlToClipboard,
      currentUrlToClipboard,
      selectedCampaignId,
      selectedMotiveId,
      hasClicked,
      replayFormats,
      DEFAULT_SALES_SLIDE_INDEX,
    };
  },
};
</script>

<style lang="scss" scoped>
details > summary::-webkit-details-marker {
  display: none;
}

.icon {
  width: 1rem;
  height: 1rem;
  margin-bottom: auto;
  margin-top: auto;
  transition: transform 0.3s ease;
}

details[open] .icon {
  transform: rotate(180deg);
}
</style>
