import { useMutation } from '@/utils/useMutation';
import { PublicPreviewHttpFacade } from '@/features/public-preview/infrastructure/public-preview-http-facade';
import { useStore } from 'vuex';
import { publicPreviewActionKeys } from '@/features/public-preview/store/store';
import { useRouter } from 'vue-router';
import { publicPreviewRouteNames } from '@/features/public-preview/presentation/routes/publicPreviewRouteNames';
import { useToast } from 'vue-toastification';
import { t } from '@/services/i18n';

const translationBaseScope = 'publicPreviews';

const getMotives = async (ids: string[], facade: PublicPreviewHttpFacade) => {
  const result = await facade.getMotivesByIds(ids);

  if (result.isRight()) {
    return result.extract();
  }
  throw new Error();
};

export const useGetPublicPreviewFormats = () => {
  const store = useStore();
  const router = useRouter();
  const toast = useToast();

  return useMutation(async (name: string) => {
    const httpFacade = new PublicPreviewHttpFacade();

    const result = await httpFacade.getPublicPreviews({ name });

    if (result.isLeft()) {
      const { authError } = result.extract();

      if (authError) {
        const currentLocation = new URL(window.location.href);

        await router.push({
          name: publicPreviewRouteNames.publicPreviewLogin,
          params: {
            nextPageQueryString: currentLocation.searchParams.toString(),
            typeOfError: 'auth',
          },
        });

        return;
      }

      toast.error(t(`${translationBaseScope}.toast.error`));
      return;
    }

    const publicPreviews = result.extract();

    await store.dispatch(publicPreviewActionKeys.setPublicPreviews, {
      publicPreviews,
    });

    const motives = await getMotives(
      store.getters.getPreviewMotivesIds(),
      httpFacade,
    );

    await store.dispatch(publicPreviewActionKeys.setMotives, { motives });
  });
};
