import { CreateSlideElementParams } from '../valueObjects/blocks/slideElement';
import { CreateSlideImageParams } from '../valueObjects/blocks/slideImage';
import { CreateSlideInterfererParams } from '../valueObjects/blocks/slideInterferer';
import { CreateSlidePriceParams } from '../valueObjects/blocks/slidePrice';
import { CreateSlideTextParams } from '../valueObjects/blocks/slideText';
import {
  CreateSlideParams,
  DEFAULT_SALES_SLIDE_INDEX,
} from '../valueObjects/slide';
import { FormatRaw } from './formatRaw';
import { deepCopyJson } from '@/core/helpers';

type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

export class FormatDefault {
  constructor(
    public id: string,
    public name: string,
    public motiveId: string,
    public isActive: boolean,
    public slides: Record<string, CreateSlideParams>,
    public slideOrder: string[],
    public motiveName?: string,
    public size?: number,
  ) {}

  static createIntersect(defaults: FormatDefault[], motiveName: string) {
    const allSlideIds = [...new Set(defaults.map(d => d.slideOrder).flat())];
    const slideOrder = allSlideIds.filter(id =>
      defaults.every(def => def.slideOrder.includes(id)),
    );

    const slides: Record<string, DeepPartial<CreateSlideParams>> = deepCopyJson(
      defaults[0].slides,
    );

    defaults.forEach(defaultFormat => {
      slideOrder.forEach(slideId => {
        slides[slideId] = this.getSlideIntersection(
          defaultFormat.slides[slideId],
          slides[slideId],
        );
      });
    });

    const intersection = FormatDefault.create({
      id: 'global_format_id',
      name: motiveName,
      motiveId: defaults[0]?.motiveId,
      isActive: false,
      slideOrder: slideOrder,
      slides: slides as any,
    });

    return intersection;
  }

  static create(params: {
    id: string;
    name: string;
    motiveId: string;
    isActive: boolean;
    slides: Record<string, CreateSlideParams>;
    slideOrder: string[];
    motiveName?: string;
    size?: number;
  }) {
    return new FormatDefault(
      params.id,
      params.name,
      params.motiveId,
      params.isActive,
      params.slides,
      params.slideOrder ?? Object.keys(params.slides ?? {}),
      params.motiveName,
      params.size,
    );
  }

  static merge(
    defaultFormat: FormatDefault,
    specificFormat?: FormatRaw,
  ): FormatDefault {
    if (!specificFormat) {
      return defaultFormat;
    }

    const mergedSlides = this.mergeSlides(
      defaultFormat.slides,
      specificFormat.slides,
    );
    const slidesOrder = (
      specificFormat.slideOrder ??
      defaultFormat.slideOrder ??
      []
    ).filter(id => !!mergedSlides[id]);

    return new FormatDefault(
      specificFormat.id ?? defaultFormat.id,
      specificFormat.name ?? defaultFormat.name,
      specificFormat.motiveId ?? defaultFormat.motiveId,
      specificFormat.isActive ?? defaultFormat.isActive,
      mergedSlides,
      slidesOrder,
      specificFormat.motiveName ?? defaultFormat.motiveName,
      specificFormat.size ?? defaultFormat.size,
    );
  }

  private static mergeSlides(
    defaultFormatSlides: Record<string, CreateSlideParams> = {},
    specificFormatSlides: Record<
      string,
      Partial<CreateSlideParams> | undefined
    > = {},
  ) {
    const keyValuePairs = Object.entries(specificFormatSlides);

    return keyValuePairs.reduce((map, [key, slide]) => {
      if (slide === null) {
        delete map[key];
      } else if (!defaultFormatSlides[key]) {
        // It's a new slide, must merge with the default sales slide
        const defaultSlidesIds = Object.keys(defaultFormatSlides);
        const salesSlideId = defaultSlidesIds[DEFAULT_SALES_SLIDE_INDEX];

        map[key] = this.mergeSlide(
          deepCopyJson(defaultFormatSlides[salesSlideId]),
          deepCopyJson(slide || {}),
        );
        map[key].isDefault = false;
        map[key].removed = false;
      } else {
        map[key] = this.mergeSlide(
          deepCopyJson(defaultFormatSlides[key]),
          deepCopyJson(slide || {}),
        );
      }

      return map;
    }, defaultFormatSlides);
  }

  private static mergeSlide(
    defaultSlide: CreateSlideParams | null,
    specificSlide: Partial<CreateSlideParams>,
  ): CreateSlideParams {
    return {
      id: specificSlide.id ?? defaultSlide?.id ?? '',
      contractId: specificSlide.contractId ?? defaultSlide?.contractId ?? '',
      deviceId: specificSlide.deviceId ?? defaultSlide?.deviceId,
      clickUrl: specificSlide.clickUrl ?? defaultSlide?.clickUrl ?? '',
      duration: specificSlide.duration ?? defaultSlide?.duration ?? 0,
      texts: this.mergeElementsArray(defaultSlide?.texts, specificSlide.texts),
      images: this.mergeElementsArray(
        defaultSlide?.images,
        specificSlide.images,
      ),
      prices: this.mergeElementsArray(
        defaultSlide?.prices,
        specificSlide.prices,
      ),
      interferers: this.mergeElementsArray(
        defaultSlide?.interferers,
        specificSlide.interferers,
      ),
      removed: specificSlide.removed ?? defaultSlide?.removed ?? false,
      isDefault: specificSlide.isDefault ?? defaultSlide?.isDefault ?? false,
    };
  }

  private static mergeElementsArray<T extends CreateSlideElementParams>(
    defaultElements?: T[],
    specificElements?: T[],
  ) {
    const map: Record<string, T> = {};

    defaultElements?.forEach(element => {
      map[element.label] = element;
    });

    specificElements?.forEach(element => {
      if (element.removed && map[element.label]) {
        map[element.label].removed = true;
      } else {
        map[element.label] = FormatDefault.mergeObject(
          element,
          map[element.label],
        );
      }
    });

    return Object.values(map);
  }

  private static mergeObject<T extends Record<string, any>>(
    obj: T,
    defaultObj?: T,
  ): T {
    const merged: T = { ...obj, ...defaultObj };

    const props = Object.keys(obj);

    for (const prop of props) {
      const value = (obj as any)[prop];
      const defaultValue = (defaultObj as any)?.[prop];

      if (Array.isArray(value)) {
        (merged as any)[prop] = value.map((val, index) =>
          FormatDefault.mergeObject(val, defaultValue?.[index]),
        );
      } else if (typeof value === 'object' && value !== null) {
        (merged as any)[prop] = FormatDefault.mergeObject(value, defaultValue);
      } else {
        (merged as any)[prop] = value ?? defaultValue;
      }
    }

    return merged;
  }

  private static getSlideIntersection(
    slide: CreateSlideParams,
    partial: DeepPartial<CreateSlideParams>,
  ): DeepPartial<CreateSlideParams> {
    const filterTexts = (text: DeepPartial<CreateSlideTextParams>) =>
      text.content !== undefined;

    const filterImages = (image: DeepPartial<CreateSlideImageParams>) =>
      image.content !== undefined;

    const filterPrices = (price: DeepPartial<CreateSlidePriceParams>) =>
      price.overline !== undefined ||
      price.underline !== undefined ||
      price.interval !== undefined ||
      price.value !== undefined;

    const filterInterferers = (
      interferer: DeepPartial<CreateSlideInterfererParams>,
    ) =>
      interferer.transition !== undefined ||
      interferer.animation !== undefined ||
      interferer.firstImage !== undefined ||
      interferer.secondImage !== undefined;

    return {
      id: this.matchingOrUndefined(slide.id, partial.id),
      contractId: this.matchingOrUndefined(
        slide.contractId,
        partial.contractId,
      ),
      deviceId: this.matchingOrUndefined(slide.deviceId, partial.deviceId),
      clickUrl: this.matchingOrUndefined(slide.clickUrl, partial.clickUrl),
      duration: this.matchingOrUndefined(slide.duration, partial.duration),
      texts: this.intersectElements(slide.texts, partial.texts).filter(
        filterTexts,
      ),
      images: this.intersectElements(slide.images, partial.images).filter(
        filterImages,
      ),
      prices: this.intersectElements(slide.prices, partial.prices).filter(
        filterPrices,
      ),
      interferers: this.intersectElements(
        slide.interferers,
        partial.interferers,
      ).filter(filterInterferers),
      removed: this.matchingOrUndefined(slide.removed, partial.removed),
      isDefault: this.matchingOrUndefined(slide.isDefault, partial.isDefault),
    };
  }

  private static matchingOrUndefined(objOne: any, objTwo: any) {
    return objOne === objTwo ? objOne : undefined;
  }

  private static intersectElements<T extends CreateSlideElementParams>(
    slideElements: T[],
    partialElements?: DeepPartial<T[]>,
  ) {
    const elementsByLabel: Record<string, DeepPartial<T>> = {};

    partialElements?.forEach(element => {
      if (element && element.label) {
        elementsByLabel[element.label] = element;
      }
    });

    slideElements?.forEach(element => {
      if (elementsByLabel[element.label]) {
        const intersectObject = this.intersectObject(
          element,
          elementsByLabel[element.label],
        );

        if (intersectObject && Object.keys(intersectObject).length > 0) {
          elementsByLabel[element.label] = intersectObject;
        } else {
          // Delete from map if intersection is empty
          delete elementsByLabel[element.label];
        }
      }
    });

    // Important to ensure only common labels are present
    Object.keys(elementsByLabel).forEach(label => {
      const hasElementWithLabel = slideElements.some(
        element => element.label === label,
      );

      if (!hasElementWithLabel) {
        delete elementsByLabel[label];
      }
    });

    return Object.values(elementsByLabel);
  }

  private static intersectObject<T>(
    completeObject: T,
    partialObject: DeepPartial<T>,
  ): DeepPartial<T> {
    const intersection: any = {};

    for (const prop in partialObject) {
      const partialValue = (partialObject as any)[prop];
      const completeValue = (completeObject as any)[prop];

      if (Array.isArray(partialValue)) {
        const arrayIntersect = partialValue.map((_, index) =>
          this.intersectObject(completeValue?.[index], partialValue?.[index]),
        );

        if (arrayIntersect.every(i => !!i)) {
          intersection[prop] = arrayIntersect as any;
        }
      } else if (typeof partialValue === 'object') {
        const valueIntersect = this.intersectObject(
          completeValue,
          partialValue,
        );

        if (valueIntersect) {
          intersection[prop] = valueIntersect;
        }
      } else {
        if (partialValue === completeValue) {
          intersection[prop] = partialValue;
        }
      }
    }

    return Object.keys(intersection).length > 1 ? intersection : undefined;
  }
}
