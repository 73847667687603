import { useMutation } from '@/utils/useMutation';
import { PublicPreviewHttpFacade } from '@/features/public-preview/infrastructure/public-preview-http-facade';
import { t } from '@/services/i18n';
import { useToast } from 'vue-toastification';
import { useRouter } from 'vue-router';
import { publicPreviewRouteNames } from '@/features/public-preview/presentation/routes/publicPreviewRouteNames';
import { QueryString } from '@/core/domain/queryString';

const httpFacade = new PublicPreviewHttpFacade();

export const usePasswordAuth = (qs: QueryString) => {
  const toast = useToast();
  const router = useRouter();

  return useMutation(async (password: string) => {
    const result = await httpFacade.login({ password });

    if (result.isLeft()) {
      toast.error(t('login.useLogin.error'));
      return;
    }

    await router.push({
      name: publicPreviewRouteNames.publicPreview,
      query: qs.toJSON(),
    });
  });
};
