import {
  createRouter,
  createWebHistory,
  RouteLocationNormalized,
  RouteRecordRaw,
} from 'vue-router';
import sessionLogin from '../features/session/presentation/sessionLogin.vue';
import pageNotFound from '../features/session/presentation/pageNotFound.vue';
import campaignList from '../features/campaigns/presentation/campaignList/campaignList.vue';
import campaignDetails from '../features/campaigns/presentation/campaignDetails/campaignDetails.vue';
import motiveDetails from '../features/campaigns/presentation/motiveDetails/motiveDetails.vue';
import motivePreview from '../features/campaigns/presentation/motivePreview/motivePreview.vue';
import motiveEditor from '../features/campaigns/presentation/motiveEditor/motiveEditor.vue';
import overviewList from '../features/campaigns/presentation/overviewList/overviewList.vue';

import mediaList from '../features/media/presentation/mediaList.vue';
import usersList from '../features/users/presentation/usersList.vue';
import syncList from '../features/sync/presentation/syncList.vue';
import templateList from '../features/templates/presentation/templateList.vue';

import askResetPassword from '../features/session/presentation/password/askResetPassword.vue';
import confirmResetAsked from '../features/session/presentation/password/confirmResetAsked.vue';
import newPassword from '../features/session/presentation/password/newPassword.vue';
import store from '@/store';
import { sessionRouteNames } from '@/features/session/presentation/routes/sessionRouteNames';
import { campaignsRouteNames } from '@/features/campaigns/presentation/routes/campaignsRouteNames';
import { mediaRouteNames } from '@/features/media/presentation/routes/mediaRouteNames';
import { usersRouteNames } from '@/features/users/presentation/routes/usersRouteNames';
import { syncRouteNames } from '@/features/sync/presentation/routes/syncRouteNames';
import { templateRouteNames } from '@/features/templates/presentation/routes/templateRouteNames';
import { publicPreviewRouteNames } from '@/features/public-preview/presentation/routes/publicPreviewRouteNames';
import passwordAuthentication from '@/features/public-preview/presentation/passwordAuthentication.vue';
import publicPreview from '@/features/public-preview/presentation/publicPreview.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: sessionRouteNames.sessionLogin,
    component: sessionLogin,
    meta: {
      guest: true,
    },
  },
  {
    path: '/public-preview',
    name: publicPreviewRouteNames.publicPreview,
    component: publicPreview,
  },
  {
    path: '/public-preview/login',
    name: publicPreviewRouteNames.publicPreviewLogin,
    component: passwordAuthentication,
  },
  {
    path: '/password/ask-reset-password',
    name: sessionRouteNames.password.askResetPassword,
    component: askResetPassword,
  },
  {
    path: '/password/confirm-reset-asked',
    name: sessionRouteNames.password.confirmResetAsked,
    component: confirmResetAsked,
  },
  {
    path: '/password/new',
    name: sessionRouteNames.password.new,
    component: newPassword,
  },
  {
    path: '/overview/list',
    name: campaignsRouteNames.overview,
    meta: {
      requiresAuth: true,
    },
    component: overviewList,
  },
  {
    path: '/campaigns/list',
    name: campaignsRouteNames.campaignList,
    meta: {
      requiresAuth: true,
    },
    component: campaignList,
  },
  {
    path: '/campaigns/details',
    name: campaignsRouteNames.campaignDetails,
    meta: {
      requiresAuth: true,
    },
    component: campaignDetails,
  },
  {
    path: '/motives/details',
    name: campaignsRouteNames.motiveDetails,
    meta: {
      requiresAuth: true,
    },
    component: motiveDetails,
  },
  {
    path: '/motives/preview',
    name: campaignsRouteNames.motivePreview,
    meta: {
      requiresAuth: true,
    },
    component: motivePreview,
  },
  {
    path: '/motives/editor',
    name: campaignsRouteNames.motiveEditor,
    meta: {
      requiresAuth: true,
    },
    component: motiveEditor,
  },
  {
    path: '/media',
    name: mediaRouteNames.mediaList,
    meta: {
      requiresAuth: true,
    },
    component: mediaList,
  },
  {
    path: '/sync/list',
    name: syncRouteNames.syncList,
    meta: {
      requiresAuth: true,
    },
    component: syncList,
  },
  {
    path: '/users/list',
    name: usersRouteNames.usersList,
    meta: {
      requiresAuth: true,
    },
    component: usersList,
  },
  {
    path: '/templates/list',
    name: templateRouteNames.templates,
    meta: {
      requiresAuth: true,
    },
    component: templateList,
  },
  { path: '/:pathMatch(.*)*', name: 'not-found', component: pageNotFound },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

function recordLastTwoRoute(to: RouteLocationNormalized) {
  const historyJson = localStorage.getItem('routeHistory');
  let history = historyJson ? JSON.parse(historyJson) : [];

  if (history[0] !== to.fullPath) {
    history.unshift(to.fullPath);

    if (history.length > 2) {
      history = history.slice(0, 2);
    }

    localStorage.setItem('routeHistory', JSON.stringify(history));
  }
}

router.beforeEach((to, from, next) => {
  recordLastTwoRoute(to);

  const isAuthenticated = !!store.getters.currentUser;

  if (!isAuthenticated && to.meta.requiresAuth) {
    next({ name: sessionRouteNames.sessionLogin });
    return;
  }

  if (isAuthenticated && to.meta.guest) {
    next({ name: campaignsRouteNames.campaignList });
    return;
  }

  next();
});

export default router;
