export class QueryString {
  private readonly value: string;

  constructor(queryString: string) {
    this.value = queryString;
  }

  toJSON() {
    const qs = new URLSearchParams(this.value);

    const query = {} as Record<string, string>;

    for (const [key, value] of qs) {
      query[key] = value;
    }

    return query;
  }
}
