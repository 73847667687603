
import { t } from '@/services/i18n';
import Toggle from '@vueform/toggle';
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { TemplateNames } from '@/components/table';
import {
  DateCell,
  IconCell,
  SelectAllHeaderCell,
  SortableHeaderCell,
  TextWithDescriptionCell,
} from '../../../../components/table/cellTemplates';
import { useActivateFormat } from '../../application/format/useActivateFormat';
import { useDeactivateFormat } from '../../application/format/useDeactivateFormat';
import { useFetchFormatsForMotiveId } from '../../application/format/useFetchFormatsForMotiveId';
import { motiveDetailsTableHeaders } from '../../application/motive/utils/motiveDetailsTableHeaders';
import { useGetBreadcrumbs } from '../../application/motive/utils/useGetBreadcrumbs';
import { useGetMotiveDetailsRows } from '../../application/motive/utils/useGetMotiveDetailsRows';
import { campaignsRouteNames } from '../routes/campaignsRouteNames';
import PreviewCell from './previewCell.vue';
import { generateTooltipContent } from '@/utils/generateTooltipContent';
import { useFetchMotives } from '../../application/motive/actions/useFetchMotives';

const MOTIVE_DETAILS_TABLE_PAGE_SIZE = 10;

export default {
  components: {
    TextWithDescriptionCell,
    DateCell,
    SelectAllHeaderCell,
    IconCell,
    SortableHeaderCell,
    PreviewCell,
    Toggle,
  },
  methods: {
    generateTooltipContent,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const motiveId = route.query.id as string;

    const breadcrumbs = useGetBreadcrumbs(motiveId);

    useFetchMotives({});
    useFetchFormatsForMotiveId({ motiveId });

    const rows = useGetMotiveDetailsRows(motiveId);

    const selectedRows = ref<Record<string, boolean>>({});

    const onCheckboxSelected = (rowId: string, isSelected: boolean) => {
      selectedRows.value[rowId] = isSelected;
    };
    const onSelectAll = (params: {
      selectAll: boolean;
      rowsToSelect: { id: string }[];
    }) => {
      if (!params.selectAll) {
        selectedRows.value = {};
      } else {
        selectedRows.value = Object.fromEntries(
          params.rowsToSelect.map(row => [row.id, true]),
        );
      }
    };
    const formattedRows = computed(() =>
      rows.value.map((row: { id: string }) => {
        return {
          ...row,
          isSelected: !!selectedRows.value[row.id],
        };
      }),
    );

    const searchedValue = ref('');

    const returnToCampaignDetails = () => {
      router.push({
        name: campaignsRouteNames.campaignDetails,
        query: { id: breadcrumbs.value.campaignId },
      });
    };

    const onPreviewAllFormats = () => {
      const routeUrl = router.resolve({
        name: campaignsRouteNames.motivePreview,
        query: { motiveId },
      });
      window.open(routeUrl.href, '_blank');
    };
    const onPreviewRow = (id: string) => {
      const row = rows.value.find(row => row.id === id);
      const routeUrl = router.resolve({
        name: campaignsRouteNames.motivePreview,
        query: {
          previewFormat: row?.name || '',
          motiveId,
        },
      });
      window.open(routeUrl.href, '_blank');
    };

    const { call: activateFormat } = useActivateFormat();
    const { call: deactivateFormat } = useDeactivateFormat();

    const onToggleFormatStatus = async (value: boolean, rowId: string) => {
      if (value) {
        await deactivateFormat({
          formatId: rowId,
          motiveId,
        });
      } else {
        await activateFormat({
          formatId: rowId,
          motiveId,
        });
      }
    };
    const onEditMotive = () => {
      router.push({
        name: campaignsRouteNames.motiveEditor,
        query: { id: motiveId },
      });
    };

    const onEditFormat = (formatName: string) => {
      router.push({
        name: campaignsRouteNames.motiveEditor,
        query: { id: motiveId, format: formatName },
      });
    };

    const routeHistoryJson = localStorage.getItem('routeHistory');
    const routeHistory = routeHistoryJson ? JSON.parse(routeHistoryJson) : [];

    const titleTranslationKey = computed(() => {
      if (routeHistory[1] === '/overview/list') {
        return 'campaigns.motiveDetails.overview';
      } else {
        return 'campaigns.title';
      }
    });

    const returnToCampaignOverview = () => {
      router.push({
        name:
          titleTranslationKey.value === 'campaigns.motiveDetails.overview'
            ? campaignsRouteNames.overview
            : campaignsRouteNames.campaignList,
      });
    };

    const breadcrumbFirstTitle = computed(() => t(titleTranslationKey.value));

    return {
      t,
      MOTIVE_DETAILS_TABLE_PAGE_SIZE,
      searchedValue,
      returnToCampaignOverview,
      returnToCampaignDetails,
      onPreviewAllFormats,
      headers: motiveDetailsTableHeaders,
      rows: formattedRows,
      onCheckboxSelected,
      onToggleFormatStatus,
      onSelectAll,
      selectedRows,
      TemplateNames,
      onPreviewRow,
      breadcrumbs,
      motiveId,
      onEditMotive,
      onEditFormat,
      breadcrumbFirstTitle,
    };
  },
};
