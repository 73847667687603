<template>
  <app-unauthenticated-layout>
    <div class="flex flex-col">
      <div
        class="bg-primary-150 p-2 rounded-1 border-primary-700 border mb-3"
        v-if="showPasswordWarning"
      >
        <AppTxt>
          {{ $t('publicPreviews.toast.authError') }}
        </AppTxt>
      </div>
      <form @submit.stop.prevent="handleLogin">
        <app-input
          v-model="password"
          type="password"
          :label="$t('login.form.password.label')"
          :placeholder="$t('login.form.password.placeholder')"
        />

        <app-button type="submit" class="mt-2 w-full" :isLoading="isLoading">
          {{ $t('login.form.button') }}
        </app-button>
      </form>
    </div>
  </app-unauthenticated-layout>
</template>

<script lang="ts">
import { computed, ref, watchEffect } from 'vue';
import AppUnauthenticatedLayout from '@/components/layout/unauthenticatedLayout.vue';
import { Input as AppInput } from '@/components/input';
import { Button as AppButton } from '@/components/button';
import { usePasswordAuth } from '@/features/public-preview/application/usePasswordAuth';
import { useRouter } from 'vue-router';
import { QueryString } from '@/core/domain/queryString';
import { useGetCurrentUser } from '@/features/users/application/useGetCurrentUser';
import { publicPreviewRouteNames } from '@/features/public-preview/presentation/routes/publicPreviewRouteNames';
import { Typography as AppTxt } from '@/components/typography';

export default {
  components: { AppTxt, AppButton, AppInput, AppUnauthenticatedLayout },
  setup() {
    const router = useRouter();
    const password = ref();
    const { currentUser } = useGetCurrentUser();

    const showPasswordWarning = computed(() => {
      return router.currentRoute.value.params.typeOfError === 'auth';
    });

    watchEffect(() => {
      if (currentUser.value?.id) {
        const qs = new QueryString(
          router.currentRoute.value.params.nextPageQueryString as string,
        );

        router.push({
          name: publicPreviewRouteNames.publicPreview,
          query: qs.toJSON(),
        });
      }
    });

    const { call, isLoading } = usePasswordAuth(
      new QueryString(
        router.currentRoute.value.params.nextPageQueryString as string,
      ),
    );

    const handleLogin = () => {
      call(password.value);
    };

    return {
      password,
      isLoading,
      handleLogin,
      showPasswordWarning,
    };
  },
};
</script>
