<template>
  <div>
    <div class="flex flex-row justify-between pb-1">
      <app-txt v-if="label" variant="small" class="text-gray-700 font-bold">{{
        label
      }}</app-txt>
      <button v-if="isResetVisible" @click="onReset" class="focus:outline-none">
        <reset-icon color="#6B7280" />
      </button>
    </div>
    <div class="relative h-buttonHeight mb-2">
      <div class="flex flex-row">
        <app-unit-input
          class="w-sliderInput"
          type="number"
          :modelValue="displayedValue"
          :unit="unit"
          @update:modelValue="onInput"
        />
        <div class="mt-1.5">
          <app-txt
            v-if="hasSecondaryValue"
            variant="small"
            class="text-gray-500 ml-1"
          >
            {{ `(${calculatedSecondaryValue}px)` }}
          </app-txt>
        </div>
      </div>
    </div>
    <div
      :class="[
        'justify-between text-gray-500',
        disabled ? 'hidden' : 'flex flex-row',
      ]"
    >
      <app-txt variant="small">{{ `${from * multiplier}${unit} ` }}</app-txt>
      <app-txt variant="small">{{ `${to * multiplier}${unit}` }}</app-txt>
    </div>
    <div
      :class="[
        ' items-center mt-1 relative h-slider rounded-full bg-gray-slider',
        disabled ? 'hidden' : 'flex flex-row',
      ]"
      ref="sliderRef"
    >
      <div
        class="relative flex justify-end items-center bg-primary h-slider rounded-full"
        :style="{ width: barWidth }"
      >
        <div
          class="absolute w-dragKnob "
          ref="tooltipRef"
          :style="{ bottom: `${knobSize + 4}px`, right: tooltipOffset }"
        >
          <div class="relative flex flex-col items-center">
            <div
              class="flex flex-col items-center p-1 pb-0 border border-gray-300 rounded-tooltip bg-white whitespace-nowrap"
            >
              <app-txt variant="tiny" class="pb-1 whitespace-nowrap">{{
                `${tooltipValue}${unit}`
              }}</app-txt>
              <div class="flex flex-col items-center h-0 w-tooltipBeak">
                <div
                  class="flex flex-col items-center h-tooltipBeak w-tooltipBeak"
                >
                  <img :src="Beak" alt="tooltip-beak" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-0 flex items-center justify-center">
          <button
            @mousedown="onMouseDragStart"
            @touchstart="onFingerDragStart"
            class="flex items-center justify-center rounded-full w-dragKnob min-w-dragKnob h-dragKnob shadow-dragKnob focus:outline-none bg-white z-10"
          >
            <div
              class="w-dragKnobInner h-dragKnobInner bg-primary rounded-full"
            ></div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from 'vue';
import { CoreEvents } from '@/core/domain/events';
import Beak from './assets/beak.svg';
import {
  getDisplayedValue,
  getOffsetChange,
  getOffsetProgress,
  getProgress,
  getValue,
} from './utils';
import AppTxt from '@/components/typography/typography.vue';

const knobSize = 24;

export default defineComponent({
  name: 'slider',
  components: { AppTxt },
  emits: [CoreEvents.UPDATE_MODEL_VALUE],
  props: {
    from: { type: Number, required: true },
    to: { type: Number, required: true },
    unit: { type: String, required: true },
    multiplier: { type: Number, required: true },
    tooltipPrecision: { type: Number, required: true },
    valuePrecision: { type: Number, required: true },
    label: String,
    disabled: { type: Boolean, default: false },
    modelValue: { type: Number, required: true },
    hasReset: { type: Boolean, default: false },
    initialModelValue: { type: Number, required: false },
    hasSecondaryValue: { type: Boolean, default: false },
    secondValueProvider: { type: Function, required: false },
  },
  setup(props, context) {
    const progress = ref(getProgress(props.modelValue, props));
    const dragging = ref(false);
    const dragStart = ref(0);
    const dragCurrent = ref(0);
    const displayedValue = ref(getDisplayedValue(progress.value, props));
    const sliderRef = ref<HTMLElement | null>(null);
    const tooltipRef = ref<HTMLElement | null>(null);
    const hasReset = ref(false);

    const onDragStart = (clientX: number) => {
      if (clientX) {
        dragStart.value = clientX;
        dragCurrent.value = clientX;
        dragging.value = true;
      }
    };

    const onMouseDragStart = (event: MouseEvent) => {
      onDragStart(event.clientX);
    };

    const onFingerDragStart = (event: TouchEvent) => {
      const touch = event.touches[0] || event.changedTouches[0];
      onDragStart(touch?.clientX);
    };

    const onDrag = (clientX: number) => {
      if (dragging.value && clientX) {
        dragCurrent.value = clientX;

        const offsetChange = getOffsetChange(
          dragCurrent.value,
          dragStart.value,
          sliderRef?.value?.clientWidth || 0,
        );

        progress.value = getOffsetProgress(
          offsetChange,
          getProgress(props.modelValue, props),
        );
        displayedValue.value = getDisplayedValue(progress.value, props);
      }
    };

    const onMouseDrag = (event: MouseEvent) => {
      onDrag(event.clientX);
    };

    const onFingerDrag = (event: TouchEvent) => {
      const touch = event.touches[0] || event.changedTouches[0];
      onDrag(touch.clientX);
    };

    const onDragEnd = () => {
      if (dragging.value) {
        dragging.value = false;
        context.emit(
          CoreEvents.UPDATE_MODEL_VALUE,
          getValue(progress.value, props),
        );
      }
    };

    const barWidth = computed(() => `${progress.value}%`);
    const tooltipValue = computed(() =>
      getDisplayedValue(progress.value, props),
    );
    const tooltipOffset = computed(
      () => `-${(tooltipRef?.value?.clientWidth || 0) / 2}px`,
    );

    const onInput = (value: number) => {
      displayedValue.value = value;

      progress.value = getProgress(value / props.multiplier, props);
      context.emit(
        CoreEvents.UPDATE_MODEL_VALUE,
        getValue(progress.value, props),
      );
    };

    const onReset = () => {
      hasReset.value = true;
      context.emit(CoreEvents.UPDATE_MODEL_VALUE, props.initialModelValue);
    };

    const isResetVisible = computed(() => {
      return (
        props.hasReset &&
        props.initialModelValue !== undefined &&
        props.initialModelValue !== props.modelValue
      );
    });

    watch([props], () => {
      if (hasReset.value) {
        progress.value = getProgress(props.modelValue, props);
        displayedValue.value = getDisplayedValue(progress.value, props);
        hasReset.value = false;
      }
    });

    onMounted(() => {
      window.addEventListener('mousemove', onMouseDrag);
      window.addEventListener('mouseup', onDragEnd);
      window.addEventListener('touchmove', onFingerDrag);
      window.addEventListener('touchend', onDragEnd);
    });

    onUnmounted(() => {
      window.removeEventListener('mousemove', onMouseDrag);
      window.removeEventListener('mouseup', onDragEnd);
      window.removeEventListener('touchmove', onFingerDrag);
      window.removeEventListener('touchend', onDragEnd);
    });

    const calculatedSecondaryValue = computed(() => {
      return props.hasSecondaryValue
        ? props.secondValueProvider?.(getValue(progress.value, props))
        : '';
    });

    return {
      isResetVisible,
      tooltipValue,
      displayedValue,
      progress,
      onInput,
      tooltipOffset,
      knobSize,
      tooltipRef,
      sliderRef,
      barWidth,
      onMouseDragStart,
      onFingerDragStart,
      onReset,
      Beak,
      calculatedSecondaryValue,
    };
  },
});
</script>
