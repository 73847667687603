import { t } from '@/services/i18n';
import { useToast } from 'vue-toastification';

const translationBaseScope = 'publicPreviews';

export function useMotiveCampaignUrl() {
  const toast = useToast();

  const campaignUrlToClipboard = async (
    campaignId: string,
    format: string,
    name: string,
  ) => {
    const currentUrl = new URL(window.location.href);

    currentUrl.searchParams.set('campaignId', campaignId);
    currentUrl.searchParams.set('format', format);
    currentUrl.searchParams.delete('motiveId');

    await navigator.clipboard.writeText(currentUrl.toString());

    toast.success(
      t(`${translationBaseScope}.toast.shareLinkCopied`, {
        name,
      }),
    );
  };

  const motiveUrlToClipboard = async (
    motiveId: string,
    campaignId: string,
    format: string,
    name: string,
  ) => {
    const currentUrl = new URL(window.location.href);

    currentUrl.searchParams.set('campaignId', campaignId);
    currentUrl.searchParams.set('motiveId', motiveId);
    currentUrl.searchParams.set('format', format);

    await navigator.clipboard.writeText(currentUrl.toString());

    toast.success(
      t(`${translationBaseScope}.toast.shareLinkCopied`, {
        name,
      }),
    );
  };

  const currentUrlToClipboard = async () => {
    const currentUrl = new URL(window.location.href);
    await navigator.clipboard.writeText(
      currentUrl.origin + currentUrl.pathname,
    );
    toast.success(t(`${translationBaseScope}.toast.publicPreviewUrlCopied`));
  };

  return {
    motiveUrlToClipboard,
    campaignUrlToClipboard,
    currentUrlToClipboard,
  };
}
