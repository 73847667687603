export default {
  common: {
    deletionModal: {
      title: 'Delete',
      description:
        'In order to perform this action please confirm the deletion. This can not be undone later on.',
      confirmDeleteLabel: 'Confirm deletion',
      confirmDeletePlaceholder: 'ENTER "DELETE"',
      confirmDeleteError: 'Please enter DELETE to confirm deletion',
      cancel: 'Cancel',
      confirm: 'Delete',
      expectedInput: 'DELETE',
    },
    errors: {
      serviceUnavailable:
        'The server appears to be offline and the request could not be processed.',
      internalServer: 'An error occurred while processing your request.',
      badRequest:
        'Request data appears to be invalid. Please review and try again.',
      activateMotive:
        'Unable to activate because of the motives start & end date',
    },
  },
  topbar: {
    overview: 'Overview',
    campaigns: 'Campaigns',
    assets: 'Assets',
    sync: 'Sync',
    templates: 'Templates',
    users: 'Users',
    logout: 'Logout',
    notifications: 'Notifications',
  },
  pageNotFound: {
    title: 'Page not found',
    buttonCaption: 'Back to app',
  },
  login: {
    useLogin: {
      success: 'Successfully logged in!',
      error: 'Incorrect email or password',
    },
    title: 'Welcome back',
    form: {
      email: {
        placeholder: 'E-Mail',
        label: 'E-Mail',
        errorMessage: 'The email address you entered is invalid.',
      },
      password: {
        placeholder: '******',
        label: 'Password',
        errorMessage: 'Password should have at least 8 characters',
      },
      button: 'Log in',
    },
    resetPassword: 'Forgot password?',
  },
  password: {
    useResetPassword: {
      error: 'Something went wrong',
      success: 'Password successfully updated',
    },
    useSendResetMail: {
      error: 'No user found for this email',
      success: 'Successfully resent invite',
    },
    askResetPassword: {
      pageTitle: 'Reset Password',
      email: {
        errorMessage: 'The email address you entered is invalid.',
        label: 'E-Mail',
        placeholder: 'E-Mail',
      },
      button: 'Reset Password',
    },
    confirmResetAsked: {
      pageTitle: 'Reset Password',
      title: 'Email sent',
      description:
        'Check your email with instructions sent to your email address.',
    },
    newPassword: {
      pageTitle: 'Enter New Password',
      newPassword: {
        label: 'New Password',
        errorMessage: 'Password should have at least 8 characters',
      },
      confirmPassword: {
        label: 'Confirm Password',
        errorMessage: 'Please make sure your passwords match',
      },
      button: 'Reset Password',
      noTokenError: {
        title: 'An error occurred',
        description:
          'The link you clicked on is not valid. Try again with another link',
      },
    },
  },
  media: {
    title: 'Media',
    search: 'Search',
    uploadFile: 'Upload file',
    newFolder: 'New Folder',
    sortByDate: 'Sort by date',
    emptyState: {
      noMediaTitle: 'Create your first folder or upload your first file.',
      noSearchResultTitle: 'No result matching your search',
    },
    folder: {
      files: 'files',
      file: 'file',
    },
    useCreateFolder: {
      success: 'Successfully created folder',
      error: 'Something went wrong',
    },
    useDeleteMedia: {
      success: 'Successfully deleted media',
      error: 'Something went wrong',
      assetUsedError: 'Asset is used in motive(s): {motivesUsingAsset}',
    },
    useUploadFile: {
      success: 'Successfully uploaded file',
      error: 'Something went wrong',
      fileTooLargeError: 'Max file size allowed is 20 MB',
      wrongFileTypeError:
        'Incorrect file type: only png, jpeg, gif and svg are allowed',
      invalidFileNameError:
        'Invalid filename: only A-Z, a-z, 0-9, ., =, - and _ are allowed',
    },
    useMoveAsset: {
      success: 'Successfully moved file',
      error: 'Something went wrong',
    },
    useRenameAsset: {
      success: 'Successfully renamed file',
      error: 'Something went wrong',
    },
    useRenameFolder: {
      success: 'Successfully renamed folder',
      error: 'Something went wrong',
      folderNameTakenError: 'This folder name already exists',
    },
    useMoveFolder: {
      success: 'Successfully moved folder',
      error: 'Something went wrong',
      cantMoveSyncedMediaFolderError: "The Synced Media folder can't be moved",
    },
    createFolder: {
      createFolder: 'Create Folder',
      folderName: 'Folder Name',
      folderNamePlaceholder: 'New Folder',
      cancel: 'Cancel',
      invalidFolderNameError: 'Please enter a folder name',
    },
    confirmDeleteNestedFiles: {
      title: 'Delete Files in Folder?',
      description:
        'The folder you’re trying to delete contains files. Do you want to keep the files?',
      cancel: 'Keep Files',
      confirm: 'Delete Files',
    },
    deleteMedia: {
      title: 'Delete media',
      description:
        'In order to delete the selected media please confirm the deletion. This can not be undone later on. Enter “DELETE” below.',
      confirmDeleteLabel: 'Confirm deletion',
      confirmDeletePlaceholder: 'ENTER "DELETE"',
      confirmDeleteError: 'Please enter DELETE to confirm deletion',
      cancel: 'Cancel',
      confirm: 'Delete Media',
      expectedInput: 'DELETE',
    },
    uploadMedia: {
      dropFilesHere: 'Drop your files here',
      maxSize: 'Up to 20 MB',
      allowedTypes: '.png .gif .jpg .svg .zip',
      browse: 'Browse',
      uploading: 'Uploading {filename}',
      cancel: 'Cancel',
      invalidFileError:
        'Invalid file. Please make sure your file is .png / .gif / .jpg / .svg, smaller than 20 MB and has valid filename.',
    },
    renameMedia: {
      renameFile: 'Rename File',
      renameFolder: 'Rename Folder',
      cancel: 'Cancel',
      confirm: 'Rename',
      namePlaceholder: 'Enter a new name',
      nameLabel: 'New name',
      emptyNameError: 'Please enter a new name',
    },
    dropdown: {
      moveToBin: 'Move to bin',
      rename: 'Rename',
    },
  },
  overview: {
    title: 'Overview',
    remainingDays: 'In {count} day | In {count} days',
    tableHeaders: {
      motiveName: 'MOTIVE NAME',
      clickUrl: 'CLICK-URL',
      description: 'DESCRIPTION',
      campaign: 'CAMPAIGN',
      startDate: 'START DATE',
      endDate: 'END DATE',
      status: 'STATUS',
    },
    exportButton: 'Export',
    exportModal: {
      title: 'Export Motives',
      campaignPlaceholder: 'Select Campaign',
      campaignLabel: 'Campaign',
      statusLabel: 'Status',
      statusPlaceholder: 'Select Status',
      statusActive: 'Active',
      statusInactive: 'Inactive',
      invalidStatusSelected: 'Please select a status',
      searchForCampaign: 'Search for a campaign',
      allStatus: 'All',
      success: 'Successfully exported motives',
      error: 'Error while exporting motives',
      selectAll: 'Select All',
    },
    filter: {
      filterBy: 'Filter by',
      status: 'Status',
      active: 'Active',
      inactive: 'Inactive',
      all: 'All',
      deactivatedByFeed: 'Deactivated by feed',
    },
  },
  campaigns: {
    title: 'Campaigns',
    search: 'Search',
    newCampaign: 'New campaign',
    new: 'New',
    emptyBin: 'Empty Bin',
    details: {
      newMotive: 'New motive',
      bulkCreation: 'Bulk Creation',
      new: 'New',
      search: 'Search',
      edit: 'Edit',
      bulkEdit: 'Bulk Edit',
    },
    confirmDeletionModal: {
      title: 'Are you sure?',
      description:
        'This campaign will be completely removed. This action cannot be undone.',
      cancel: 'Cancel',
      confirm: 'Confirm',
    },
    confirmDeleteMotiveModal: {
      title: 'Are you sure?',
      description:
        'This motive and all its formats will be completely removed. This action cannot be undone.',
      cancel: 'Cancel',
      confirm: 'Confirm',
    },
    duplicateMotiveModal: {
      title: 'Duplicate Motive',
      cancel: 'Cancel',
      confirm: 'Duplicate',
      campaignLabel: 'Duplicate motive to campaign',
      campaignPlaceholder: 'Select or search a campaign',
      invalidCampaignSelected: 'Please select a campaign from the list',
    },
    confirmEmptyCampaignBinModal: {
      title: 'Are you sure?',
      description:
        'All campaigns in the bin, as well as their motives and formats, will be completely removed. This action cannot be undone.',
      cancel: 'Cancel',
      confirm: 'Confirm',
    },
    motiveDetails: {
      previewAllFormats: 'Preview all formats',
      search: 'Search',
      preview: 'Preview',
      edit: 'Edit',
      size: '{size} KB',
      overview: 'Overview',
    },
    tabs: {
      allCampaigns: 'All Campaigns',
      archive: 'Archive',
      bin: 'Bin',
    },
    motiveDetailsHeaders: {
      format: 'FORMAT',
      size: 'SIZE',
      status: 'STATUS',
      device: 'DEVICE',
      contract: 'CONTRACT',
      endDate: 'END DATE',
      clickUrl: 'CLICK-URL',
      description: 'DESCRIPTION',
    },
    headers: {
      campaignName: 'CAMPAIGN NAME',
      startDate: 'START DATE',
      endDate: 'END DATE',
      size: 'File Size',
      status: 'STATUS',
      motives: 'MOTIVES',
      isDefaultMotive: 'DEFAULT',
      templateVersion: 'VERSION',
      bulkMotive: 'BULK MOTIVE',
    },
    listEmptyState: {
      noSearchResultTitle: 'No campaigns matching your search',
      noCampaignsTitle: 'No campaigns yet',
      subtitle: 'Create a campaign by clicking the button below',
      newCampaign: 'New Campaign',
    },
    detailsEmptyState: {
      noSearchResultTitle: 'No motives matching your search',
      noMotivesTitle: 'No motives yet',
      subtitle: 'Create your first motive by clicking the button below',
      newMotive: 'New motive',
    },
    createCampaign: {
      title: 'Create new Campaign',
      name: 'Name',
      namePlaceholder: 'Enter a campaign name',
      emptyNameError: 'Please enter a campaign name',
      trackingParameter: 'Tracking Parameter',
      startDate: 'Start date',
      startDatePlaceholder: 'Choose start date',
      endDate: 'End date',
      endDatePlaceholder: 'Choose end date',
      invalidDateError: 'Please enter a valid date',
      startAfterEndError: "End date can't be before start date",
      cancel: 'Cancel',
      createCampaign: 'Create campaign',
    },
    editCampaign: {
      title: 'Edit Campaign',
      name: 'Name',
      namePlaceholder: 'Enter a campaign name',
      emptyNameError: 'Please enter a campaign name',
      trackingParameter: 'Tracking Parameter',
      startDate: 'Start date',
      startDatePlaceholder: 'Choose start date',
      endDate: 'End date',
      endDatePlaceholder: 'Choose end date',
      invalidDateError: 'Please enter a valid date',
      startAfterEndError: "End date can't be before start date",
      cancel: 'Cancel',
      editCampaign: 'Edit campaign',
      motiveNb: '{count} Motives',
      updateOn: 'Update on',
      updateOff: 'Update off',
      confirmDeleteMotiveGroup: {
        title: 'Are you sure?',
        description:
          'This motive group and all its motives will be completely removed. This action cannot be undone.',
        cancel: 'Cancel',
        confirm: 'Confirm',
      },
    },
    updateMotive: {
      updateMotive: 'Confirm',
      title: 'Update Motive',
      continue: 'Continue',
      cancel: 'Cancel',
    },
    updateFormat: {
      updateFormat: 'Update Format',
      title: 'Update {formatName}',
      cancel: 'Cancel',
    },
    bulkMotiveCreation: {
      title: 'Bulk Creation',
      cancel: 'Cancel',
      confirm: 'Create Motive',
      baseMotive: 'Base Motive',
      nameLabel: 'Name',
      namePlaceholder: 'Enter a bulk',
      emptyNameError: 'Please enter a name',
      motiveLabel: 'Choose a motive',
      motivePlaceholder: 'Select or search for a motive',
      emptyMotiveError: 'Please select a motive',
      done: 'Done',
      variations: 'Variations',
      oneContractOption: 'Choose 1 contract and create all possible variations',
      oneDeviceOption: 'Choose 1 device and create all possible variations',
      allPossibilitiesOption:
        'Create all possible variations for contracts and devices',
      contractPlaceholder: 'Select or search for a contract',
      emptyContractError: 'Please select a contract',
      devicePlaceholder: 'Select or search for a device',
      emptyDeviceError: 'Please select a device',
      autoUpdate: 'Automatic Update',
      autoUpdateDescription:
        'Every time a new pairing of devices / contracts is available it will be created automatically and added to an exisitng motive as a slide or as a new motive.',
      autoUpdateCheckboxLabel: 'Enable automatic update for this motive',
    },
    createMotive: {
      title: 'Create Motive',
      cancel: 'Cancel',
      continue: 'Continue',
      createMotive: 'Create Motive',
      mediaPicker: {
        upload: 'Upload',
        chooseButtonLabel: 'Choose',
        replaceButtonLabel: 'Replace',
        removeButtonLabel: 'Remove',
        squareOptionLabel: 'Square',
        horizontalOptionLabel: 'Horizontal',
        verticalOptionLabel: 'Vertical',
        backgroundVisualLabel: 'Visual - Background',
        squareVisualLabel: '{type} - Square',
        horizontalVisualLabel: '{type} - Horizontal',
        verticalVisualLabel: '{type} - Vertical',
        visualLabel: 'Visual',
        modalTitle: 'Choose Media',
        done: 'Done',
        cancel: 'Cancel',
        requiredLabel: 'Required',
        animationInSlideException: 'Animations are only available in intros',
      },
      generalSettings: {
        title: 'General Settings',
        name: 'Name',
        description: 'Description',
        descriptionPlaceholder: 'Add a description to motive',
        namePlaceholder: 'Enter a motive name',
        emptyNameError: 'Please enter a motive name',
        clickUrl: 'Click-Through URL',
        clickUrlPlaceholder: 'https://www.congstar.de/portal/',
        defaultMotive: 'Default motive',
        defaultMotivePlaceholder: 'True or false',
        true: 'True',
        false: 'False',
        startDate: 'Start date',
        startDatePlaceholder: 'Choose start date',
        endDate: 'End date',
        endDatePlaceholder: 'Choose end date',
        invalidDateError: 'Please enter a valid date',
        startAfterEndError: "End date can't be before start date",
        done: 'Done',
        device: 'Device',
        contract: 'Contract',
        layoutLabel: 'Layout',
      },
      variations: {
        title: 'Variations',
        slideTitle: 'Slide {slideNb}',
        deviceLabel: 'Device',
        devicePlaceholder: 'Choose a device',
        emptyDeviceError: 'Please choose a device',
        contractLabel: 'Choose Contract',
        contractPlaceholder: 'Choose a Contract',
        emptyContractError: 'Please choose a contract',
        addSlide: 'Add another slide',
        done: 'Done',
        yes: 'Yes',
        no: 'No',
      },
    },
    editMotive: {
      backgroundOffsetPickerLabel: 'Image positioning',
      titleMotive: 'Edit Motive',
      titleFormat: 'Edit {format}',
      resetChanges: 'Reset Changes',
      save: 'Save',
      deletedContract:
        'The contract for slide {slideNumber} has been deleted. Please select another one.',
      deletedDevice:
        'The device for slide {slideNumber} has been deleted. Please select another one.',
      unsavedExitModal: {
        exitTitle: 'Are you sure you want to exit?',
        exitDescription: 'Your motive creation progress will be lost.',
        exitButtonLabel: 'Exit without saving',
        saveAndExitButtonLabel: 'Save & Exit',
        cancelButtonLabel: 'Go Back to Editing',
      },
      unsavedContinueModal: {
        exitTitle: 'Are you sure you want to continue?',
        exitDescription: 'Your motive creation progress will be lost.',
        exitButtonLabel: 'Continue without saving',
        saveAndExitButtonLabel: 'Save & Continue',
        cancelButtonLabel: 'Go Back to Editing',
      },
      resetChangesModal: {
        title: 'Are you sure you want to reset?',
        description: 'Your motive creation progress will be lost.',
        exitButtonLabel: 'Cancel',
        resetChangesLabel: 'Reset changes',
        success: 'Changes successfully reset',
      },
      generalSettings: 'General Settings',
      editAllFormats: 'Edit all Formats',
      invalidSettingsError: {
        variationSection: 'Please go to general settings and choose a contract',
        generic: 'Invalid settings provided',
      },
      visualType: {
        background: 'Background',
        visual: 'Visual',
      },
      slideSettings: {
        deviceLabel: 'Device',
        devicePlaceholder: 'Choose a device',
        emptyDeviceError: 'Please choose a device',
        contractLabel: 'Contract',
        contractPlaceholder: 'Choose a Contract',
        emptyContractError: 'Please choose a contract',
        title: 'Slides',
        titleShort: 'Slides',
        slideTitle: 'Slide {slideNb}',
        done: 'Done',
        addElements: 'Add more elements',
        addSlide: 'Add another slide',
        slideDurationLabel: 'Slide duration',
        clickUrlLabel: 'Click-URL',
        clickUrlPlaceholder: 'https://www.congstar.de/portal/ *',
        headlineLabel: 'Headline',
        headlinePlaceholder: '',
        sublineLabel: 'Subline',
        sublinePlaceholder: 'Enter a subline',
        headlineSizeLabel: 'Headline Size',
        sublineSizeLabel: 'Subline Size',
        headlineSpacingBottomLabel: 'Space below headline',
        '20%': '20%',
        '30%': '30%',
        '40%': '40%',
        '50%': '50%',
        imageZoomLabel: 'Image Width',
        visualScalingVerticalLabel: 'Visual Scaling Vertical',
        visualScalingHorizontalLabel: 'Visual Scaling Horizontal',
        visualShiftVerticalLabel: 'Visual Shift Vertical',
        visualShiftHorizontalLabel: 'Visual Shift Horizontal',
        alignLabel: 'Align',
        offsetLabel: 'Offset',
        priceOverlineLabel: 'Price Overline',
        priceOverlinePlaceholder: 'Enter a price overline',
        priceLabel: 'Price',
        priceIntervalLabel: 'Price Interval',
        priceIntervalPlaceholder: 'Enter a price interval',
        monthly: 'mtl.',
        yearly: 'yrl.',
        priceUnderlineLabel: 'Price Underline',
        priceUnderlinePlaceholder: 'Enter a price underline',
        priceScalingLabel: 'Price Scaling',
        priceBlockScalingLabel: 'Price Block Scaling',
        priceSpacingTopLabel: 'Price Spacing Top',
        ctaWordingLabel: 'CTA Wording',
        ctaWordingPlaceholder: 'e.g. "Buy Now"',
        ctaScalingLabel: 'CTA Scaling',
        ctaSpacingBottomLabel: 'CTA Spacing Bottom',
        ctaFontSizeLabel: 'CTA Font Size',
        alignDeviceLabel: 'Align Device',
        alignPriceLabel: 'Align Price',
        alignVisualLabel: 'Align Visual',
        top: 'top',
        bottom: 'bottom',
        center: 'center',
        left: 'left',
        right: 'right',
        subline: 'Subline',
        bullets: 'Bullets',
        bullet1Label: 'Bullet 1 content',
        bullet1Placeholder: 'Bullet 1',
        bullet1TypeLabel: 'Bullet 1 visual',
        bullet2Label: 'Bullet 2 content',
        bullet2Placeholder: 'Bullet 2',
        bullet2TypeLabel: 'Bullet 2 visual',
        bullet3Label: 'Bullet 3 content',
        bullet3Placeholder: 'Bullet 3',
        bullet3TypeLabel: 'Bullet 3 visual',
        checkmark: 'Checkmark',
        iconA: 'Icon A',
        iconB: 'Icon B',
        bulletSizeLabel: 'Bullet Size',
        areBulletsSpreadLabel: 'Bullets spread across whole length',
        yes: 'Yes',
        no: 'No',
        generalSectionLabel: 'General',
        visualSectionLabel: 'Visual',
        priceSectionLabel: 'Text / Price',
        ctaSectionLabel: 'CTA',
        transitionTypeLabel: 'Transition',
        transitionOriginLabel: 'Animation Origin',
        interfererSectionLabel: 'Interferer',
        interfererAnimationLabel: 'Animation',
        interferers: {
          0: 'First',
          1: 'Second',
          graphicLabel: '{interfererNumber} Graphic',
          textLabel: 'Text - {interfererNumber} Graphic',
        },
        interfererAnimations: {
          default: 'Default',
        },
        bulletsSectionLabel: 'Bullets',
        text: {
          label: 'Text',
          size: 'Text Size',
          lineSpacing: 'Line Spacing',
          position: 'Text Position',
          blockWidth: 'Text Block Width',
        },
        price: {
          valueLabel: 'Price',
          overlineLabel: 'Price Overline',
          intervalLabel: 'Price Interval',
          underlineLabel: 'Price Underline',
          blockWidthLabel: 'Price Block Width',
          positionLabel: 'Price Position',
          scaling: 'Price Scaling',
        },
        image: {
          label: 'Image',
          positionLabel: 'Image Position',
        },
      },
      backgroundSettings: {
        zoomLabel: 'Background zoom',
        offsetLabel: 'Background offset',
      },
      interfererSettings: {
        alignVisualLabel: 'Align Visual',
        alignLabel: 'Align',
        offsetLabel: 'Offset',
        imageZoomLabel: 'Image Width',
        interfererTextPlaceholder: 'Interferer text',
        interfererTextRequiredError: 'Interferer text is required',
      },
      topbar: {
        dropdownTitle: 'File',
        livePreview: 'Live preview',
        interactivity: 'Interactivity',
        formatFilterPlaceholder: 'Filter size',
        allFormatsFilterLabel: 'All formats',
        versionHistory: 'Version History',
        replay: 'Replay',
        format: 'Format',
        formats: 'Formats',
      },
      versionHistory: {
        emptyState: {
          title: 'There are no past versions saved yet',
          description: 'Start editing to create versions',
        },
        versionType: {
          autosaved: 'Change in',
          restored: 'Restored Version',
        },
        lastVersion: 'Last version',
        restoredFrom: 'Restored from',
        succeed: 'Version restored',
        save: 'Use this version',
        close: 'Close version history',
      },
      reset: {
        title: 'Are you sure you want to reset this field?',
        description:
          'All data and changes will be reset and cannot be recovered once you confirm.',
        cancel: 'Cancel',
        confirm: 'Confirm',
      },
    },
    useCreateCampaign: {
      success: 'Campaign was successfully created',
      error: 'Something went wrong',
    },
    useDeactivateCampaign: {
      success: 'Successfully deactivated campaign',
      error: 'Something went wrong',
    },
    useDuplicateCampaign: {
      success: 'Successfully duplicated campaign',
      error: 'Something went wrong',
    },
    useActivateCampaign: {
      success: 'Successfully activated campaign',
      error: 'Something went wrong',
    },
    useDeleteCampaign: {
      success: 'Successfully moved campaign to bin',
      error: 'Something went wrong',
    },
    useExportCampaign: {
      success: 'Csv export url copied to clipboard !',
      error: 'Error while copying the url',
    },
    useHardDeleteCampaign: {
      success: 'Successfully deleted campaign',
      error: 'Something went wrong',
    },
    useUpdateCampaign: {
      success: 'Successfully edited campaign',
      error: 'Something went wrong',
    },
    useCreateMotive: {
      success: 'Motive was successfully created',
      error: 'Something went wrong',
    },
    useCreateMotiveVersion: {
      success: 'Version for motive was successfully created',
      error: 'Something went wrong when creating a new version',
    },
    useUpdateMotive: {
      success: 'Motive was successfully updated',
      error: 'Something went wrong',
    },
    useUpdateFormat: {
      success: 'Format was successfully updated',
      error: 'Something went wrong',
    },
    useDeactivateMotive: {
      success: 'Motive was successfully deactivated',
      error: 'Something went wrong',
    },
    useActivateMotive: {
      success: 'Motive was successfully activated',
      error: 'Something went wrong',
    },
    useActivateFormat: {
      success: 'Format was successfully activated',
      error: 'Something went wrong, please try again later',
    },
    useDeactivateFormat: {
      success: 'Format was successfully deactivated',
      error: 'Something went wrong, please try again later',
    },
    useDeleteMotive: {
      success: 'Motive was successfully deleted',
      error: 'Something went wrong',
    },
    useEmptyCampaignBin: {
      success: 'Successfully emptied campaign bin',
      error: 'Something went wrong',
    },
    useDuplicateMotive: {
      success: 'Motive was successfully duplicated',
      error: 'Something went wrong',
    },
    useBulkCreateMotive: {
      success: 'Motive group was successfully created',
      error: 'Something went wrong',
    },
    useDeleteMotiveGroup: {
      success: 'Successfully deleted motive group',
      error: 'Something went wrong',
    },
    dropdown: {
      deactivate: 'Deactivate',
      archive: 'Archive',
      activate: 'Activate',
      moveToBin: 'Move to bin',
      export: 'Export CSV',
      duplicate: 'Duplicate',
      hardDelete: 'Delete',
      editMotive: 'Edit motive',
      editCampaign: 'Edit campaign',
      delete: 'Delete',
      addMotive: 'Add motive',
    },
    previewMotive: {
      title: 'Preview {motiveName}',
      formatFilterPlaceholder: 'Filter size',
      allFormatsFilterLabel: 'All formats',
    },
  },
  notifications: {
    title: 'Notifications',
    save: 'Save',
    cancel: 'Cancel',
    motivesNotifications: 'Start and End of Motives',
    campaignsNotifications: 'End of Campaigns',
    motivesEditingNotifications: 'Editing of Motives',
    motivesDeactivationNotifications: 'Automatic Deactivation of Motives',
    turnOffAllNotifications: 'Turn Off all Notifications',
  },
  table: {
    active: 'Active',
    inactive: 'Inactive',
    invited: 'Invited',
    success: 'Successful',
    error: 'Error',
    ADMIN: 'Admin',
    AD_MANAGER: 'Ad Manager',
  },
  sync: {
    title: 'Sync',
    search: 'Search',
    syncNow: 'Sync Now',
    emailGroupBtn: 'Email Group ({membersNb})',
    deletedVariationsNb: '{deletedVariations} deleted variations',
    headers: {
      sync: 'SYNC',
      time: 'TIME',
      duration: 'DURATION',
      events: 'EVENTS',
      status: 'STATUS',
    },
    emailGroup: {
      title: 'Sync Email Group',
      description:
        'This email group receives an email with detailed information in case of success or error.',
      confirm: 'Update Email Group',
      emailGroupLabel: 'Enter email addresses',
    },
    useUpdateSyncEmailGroup: {
      success: 'Successfully updated email group',
      error: 'Something went wrong',
    },
    useStartManualSync: {
      success: 'Successfully started a synchronization',
      error: 'Something went wrong',
    },
  },
  templates: {
    headers: {
      templateName: 'Template Name',
      description: 'Description',
    },
    version: 'Template - v{version}',
    errorDownload: 'Your download failed. Please try again',
    errorUpdate: 'Update template failed, please try again',
    successUpdate: 'Template updated',
  },
  users: {
    title: 'User',
    search: 'Search',
    newUser: 'New user',
    headers: {
      users: 'USERS',
      role: 'ROLE',
      creationDate: 'DATE USER CREATED',
      status: 'STATUS',
    },
    confirmDeletionModal: {
      title: 'Are you sure?',
      description: 'This user will be removed. This action cannot be undone.',
      cancel: 'Cancel',
      confirm: 'Confirm',
    },
    emptyState: {
      title: 'No results matching your search',
      subtitle: 'Check your spelling or try a different search term',
    },
    inviteUsers: {
      title: 'Invite new users',
      subtitle:
        'Please provide us with the E-mail and set the role of the new user.',
      email: 'E-Mail',
      emailPlaceholder: 'Enter the e-mail',
      invalidEmailError: 'Please enter a valid email',
      role: 'Role',
      rolePlaceholder: 'Choose a role',
      invalidRoleError: 'Please choose a role',
      addAnotherUserBtn: 'Add another user',
      cancel: 'Cancel',
      inviteUsers: 'Invite users',
    },
    useCreateUsers: {
      success: 'Successfully invited {count} users',
      error: 'Something went wrong',
    },
    useUpdateUserRole: {
      success: 'Successfully updated user',
      error: 'Something went wrong',
    },
    useUpdateEnabledNotifications: {
      success: 'Successfully updated user notification settings',
      error: 'Something went wrong',
    },
    useDeactivateUser: {
      success: 'Successfully deactivated user',
      error: 'Something went wrong',
    },
    useActivateUser: {
      success: 'Successfully activated user',
      error: 'Something went wrong',
    },
    useDeleteUser: {
      success: 'Successfully deleted user',
      error: 'Something went wrong',
    },
    changeRole: {
      changeRole: 'Change role',
      cancel: 'Cancel',
      role: 'Role',
      rolePlaceholder: 'Choose a role',
      invalidRoleError: 'Please choose a role',
      admin: 'Admin',
      adManager: 'Ad Manager',
    },
    dropdown: {
      changeRole: 'Change role',
      deactivate: 'Deactivate',
      activate: 'Activate',
      delete: 'Delete',
      resendInvite: 'Resend invite',
    },
  },
  publicPreviews: {
    title: 'All active campaigns',
    sharePreviewButton: 'Share Preview',
    replayButton: 'Replay',
    shareCampaignButton: 'Share Campaign',
    motiveNameLabel: 'Motive:',
    motiveStartDateLabel: 'Start Date:',
    motiveEndDateLabel: 'End Date:',
    motiveDaysRemainingLabel: 'In {count} day | In {count} days',
    shareMotiveButton: 'Share',
    toast: {
      authError: 'Provide a password to access the previews',
      error: 'Something went wrong',
      shareLinkCopied: 'Link copied to clipboard',
    },
  },
};
