
import { useGetCurrentUser } from '@/features/users/application/useGetCurrentUser';
import { defineComponent, ref } from 'vue';
import topbarLogo from './assets/topbarLogo.png';
import { useRoute, useRouter } from 'vue-router';
import { useGetAccessiblePages } from './useGetAccessiblePages';
import { useLogout } from '@/features/session/application/useLogout';
import { LayoutEvents } from './layoutEvents';
import EnabledNotificationsModal from '@/features/users/presentation/enabledNotificationsModal.vue';

export default defineComponent({
  name: 'topbar',
  components: { EnabledNotificationsModal },
  emits: [LayoutEvents.TOGGLE_SIDEBAR],
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { logout } = useLogout();
    const { currentUser } = useGetCurrentUser();
    if (!currentUser.value.enabledNotifications) {
      logout();
    }
    const topbarPages = useGetAccessiblePages();
    const isNotificationsSettingsModalOpen = ref(false);
    const setEnabledNotificationsModalOpen = (value: boolean) => {
      isNotificationsSettingsModalOpen.value = value;
    };

    const onPageClick = (
      newPageName: string,
      reloadPageOnClick?: boolean,
      openInNewTab?: boolean,
      pagePath?: string,
    ) => {
      if (newPageName === route.name && reloadPageOnClick) {
        router.go(0);
        return;
      }

      if (openInNewTab) {
        window.open(window.location.origin + pagePath, '_blank');
        return;
      }

      router.push({ name: newPageName });
    };

    return {
      currentUser,
      topbarLogo,
      topbarPages,
      router,
      logout,
      isNotificationsSettingsModalOpen,
      setEnabledNotificationsModalOpen,
      LayoutEvents,
      onPageClick,
    };
  },
});
