<template>
  <div>
    <div class="w-full relative">
      <div class="flex flex-row justify-between pb-1" v-if="label || hasReset">
        <app-txt
          v-if="label"
          variant="small"
          class="text-gray-700 font-medium"
          >{{ label }}</app-txt
        >
        <button
          v-if="isResetVisible"
          @click="onReset"
          class="focus:outline-none"
        >
          <reset-icon color="#6B7280" />
        </button>
      </div>
      <dropdown-wrapper
        :class="{
          'left-0 right-0 max-h-select overflow-y-auto': true,
          'top-10': label,
          'top-6': !label,
          'min-w-dropdownWidth': !limitDropdownWidth,
        }"
        :disabled="disabled"
      >
        <template v-slot:trigger>
          <div
            :class="{
              'relative flex flex-row items-center w-full py-2 px-2 rounded-1 border rounded-md  h-buttonHeight': true,
              'mt-1': !!label,
              'border-red-600': errorMessage,
              'border-gray-300': !errorMessage,
              'bg-gray-disabled': disabled,
            }"
            :title="selectedLabel"
          >
            <app-txt variant="small" class="text-gray-500 truncate pr-2">{{
              selectedLabel || placeholder
            }}</app-txt>

            <div class="absolute pr-2 right-0" v-if="errorMessage">
              <app-icon name="invalid" alt="invalid icon" />
            </div>
            <div class="absolute right-0 pr-2" v-else>
              <app-icon name="chevronDown" alt="chevron down icon" />
            </div>
          </div>
        </template>
        <template v-slot:dropdown>
          <dropdown-item
            class="flex flex-row w-full"
            v-for="option in options"
            :key="option.value"
            @click="() => onSelect(option.value)"
          >
            <app-txt variant="small" class="ml-1.5 w-full">
              {{ option.label }}
            </app-txt>
          </dropdown-item>
        </template>
      </dropdown-wrapper>
    </div>

    <span class="text-red-600" v-if="errorMessage">{{ errorMessage }}</span>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { CoreEvents } from '@/core/domain/events';

export default defineComponent({
  name: 'app-select',
  emits: [CoreEvents.UPDATE_MODEL_VALUE],
  props: {
    placeholder: String,
    label: String,
    errorMessage: String,
    options: Object({ value: String, label: String }),
    modelValue: [String, Boolean, Number],
    disabled: { type: Boolean, default: false },
    hasReset: { type: Boolean, default: false },
    initialModelValue: [String, Boolean, Number],
    limitDropdownWidth: { type: Boolean, default: false },
  },
  setup(props, context) {
    const selectedLabel = computed(
      () =>
        props.options.find(
          (option: { value: string; label: string }) =>
            option.value === props.modelValue,
        )?.label,
    );

    const onSelect = (value: string | boolean | number) => {
      context.emit(CoreEvents.UPDATE_MODEL_VALUE, value);
    };

    const onReset = () => {
      context.emit(CoreEvents.UPDATE_MODEL_VALUE, props.initialModelValue);
    };

    const isResetVisible = computed(() => {
      return (
        props.hasReset &&
        props.initialModelValue &&
        props.initialModelValue !== props.modelValue
      );
    });

    return {
      isResetVisible,
      onReset,
      onSelect,
      selectedLabel,
    };
  },
});
</script>
