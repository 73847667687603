import { useStore } from '@/store';
import { computed, Ref } from 'vue';
import { useGetAssetsById } from '@/features/media/application/useGetAssetsById';
import { useGetDevicesById } from '@/features/campaigns/application/device/useGetDevicesById';
import { useGetContractsById } from '@/features/campaigns/application/contract/useGetContractsById';
import { FormatPreview } from '@/features/campaigns/domain/format/formatPreview';
import { DateWrapper } from '@/core';

export const useCreateFormatPreviews = (replay: Ref<boolean>) => {
  const store = useStore();

  const previews = computed(() => store.getters.getPreviews());
  const assetsById = useGetAssetsById();
  const devicesById = useGetDevicesById();
  const contractsById = useGetContractsById();

  return computed(() => {
    // this is only done in order to force the computed property to run again.
    // that's the only way we have to achieve the replay functionality
    const _ = replay.value;

    return previews.value.map(preview => {
      const formatPreviews = preview.motives
        .map(motive => {
          const foundMotive = store.getters.getPublicPreviewMotiveById(
            motive.motiveId,
          );

          if (!foundMotive) return;

          const preview = FormatPreview.create({
            motive: foundMotive,
            specificFormat: motive.format,
            defaultFormat: motive.formatDefaultInstance,
            devicesById: devicesById.value,
            imagesById: assetsById.value,
            contractsById: contractsById.value,
          });

          let daysRemaining: number | undefined = DateWrapper.createFromDate(
            new Date(),
          ).getDiffInDays(foundMotive.endDate);

          if (daysRemaining < 0) daysRemaining = 0;
          if (daysRemaining > 14) daysRemaining = undefined;

          return {
            motiveId: motive.motiveId,
            motiveName: foundMotive.name,
            motiveStartDate: foundMotive.startDate.toTableFormatWithHours(),
            motiveEndDate: foundMotive.endDate.toTableFormatWithHours(),
            daysRemaining,
            templateVersion: foundMotive.templateVersion,
            formatPreview: preview,
          };
        })
        .filter(Boolean);

      return {
        campaignId: preview.campaignId,
        campaignName: preview.campaignName,
        motives: formatPreviews,
      };
    });
  });
};
