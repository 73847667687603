import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import {
  HttpErrorResponse,
  HttpService,
  LocalStorageService,
  RequestCancelledException,
  ServerException,
} from '@/core';
import { StorageService } from '..';
import { getEnvVariable } from '@/utils/getEnvVariable';

enum HttpStatusCode {
  Unauthorized = 401,
  ServerError = 500,
}

const baseUrl = getEnvVariable('VUE_APP_API_BASE_URL');

async function requestTokenRefresh(refreshToken: string) {
  return await axios.post(baseUrl + 'auth/refresh', {
    refreshToken: refreshToken,
  });
}

export class AxiosService implements HttpService {
  protected readonly instance: AxiosInstance;

  constructor(
    baseURL = baseUrl,
    public storageService: StorageService = new LocalStorageService(),
  ) {
    this.instance = axios.create({ baseURL, withCredentials: true });

    this.registerRequestInterceptor();
    this.registerResponseParserInterceptor();
    this.registerResponseErrorHandlerInterceptor();
  }

  async get(path: string, config?: AxiosRequestConfig) {
    try {
      return await this.instance.get(path, config);
    } catch (error) {
      this.manageErrors(error);
    }
  }

  async post<T>(path: string, payload: T, config: AxiosRequestConfig = {}) {
    try {
      return await this.instance.post(path, payload, config);
    } catch (error) {
      this.manageErrors(error);
    }
  }

  async put<T>(path: string, payload: T, config: AxiosRequestConfig = {}) {
    try {
      return await this.instance.put(path, payload, config);
    } catch (error) {
      this.manageErrors(error);
    }
  }

  async patch<T>(path: string, payload: T, config: AxiosRequestConfig = {}) {
    try {
      return await this.instance.patch(path, payload, config);
    } catch (error) {
      this.manageErrors(error);
    }
  }

  async delete<T>(path: string, data?: T) {
    try {
      return await this.instance.delete(path, {
        data,
      });
    } catch (error) {
      this.manageErrors(error);
    }
  }

  manageErrors(e: HttpErrorResponse & Error) {
    if (axios.isCancel(e)) {
      throw new RequestCancelledException();
    }

    throw new ServerException(
      e?.response?.status || HttpStatusCode.ServerError,
      e?.response?.data?.message || e?.message || 'Unexpected error',
      e?.response?.data,
    );
  }

  private registerRequestInterceptor() {
    const attachBearerToken = (config: AxiosRequestConfig) => {
      const token = this.storageService.get('accessToken');

      return {
        ...config,
        headers: token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : undefined,
      };
    };

    this.instance.interceptors.request.use(attachBearerToken, error =>
      Promise.reject(error),
    );
  }

  private registerResponseParserInterceptor() {
    const parse = (response: AxiosResponse) => ({
      ...response,
      response: response.data.response
        ? JSON.parse(response.data.response)
        : null,
    });

    this.instance.interceptors.response.use(parse, error =>
      Promise.reject(error),
    );
  }

  private registerResponseErrorHandlerInterceptor() {
    const handleUnauthorized = async (error: AxiosError) => {
      if (
        !error.config ||
        !error.response ||
        error.response.status !== HttpStatusCode.Unauthorized
      ) {
        return Promise.reject(error);
      }

      const shouldTryTokenRefresh = !!error.config.headers['Authorization'];

      if (error.config.url === 'auth/login' || !shouldTryTokenRefresh) {
        return Promise.reject(error);
      }

      return await this.refreshToken(error);
    };

    this.instance.interceptors.response.use(undefined, handleUnauthorized);
  }

  private async refreshToken(error: AxiosError) {
    try {
      const response = await requestTokenRefresh(
        this.storageService.get('refreshToken'),
      );

      this.storageService.set('accessToken', response.data.meta?.accessToken);
      this.storageService.set('refreshToken', response.data.meta?.refreshToken);

      const token = this.storageService.get('accessToken');

      return axios.request({
        ...error.config,
        headers: {
          ...error.config.headers,
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (e) {
      if (error.config.url !== 'auth/login') {
        this.storageService.clear();
        window.location.reload();
      }
    }
  }
}
