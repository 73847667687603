
import { computed, ref, watchEffect } from 'vue';
import AppUnauthenticatedLayout from '@/components/layout/unauthenticatedLayout.vue';
import { Input as AppInput } from '@/components/input';
import { Button as AppButton } from '@/components/button';
import { usePasswordAuth } from '@/features/public-preview/application/usePasswordAuth';
import { useRouter } from 'vue-router';
import { QueryString } from '@/core/domain/queryString';
import { useGetCurrentUser } from '@/features/users/application/useGetCurrentUser';
import { publicPreviewRouteNames } from '@/features/public-preview/presentation/routes/publicPreviewRouteNames';
import { Typography as AppTxt } from '@/components/typography';

export default {
  components: { AppTxt, AppButton, AppInput, AppUnauthenticatedLayout },
  setup() {
    const router = useRouter();
    const password = ref();
    const { currentUser } = useGetCurrentUser();

    const showPasswordWarning = computed(() => {
      return router.currentRoute.value.params.typeOfError === 'auth';
    });

    watchEffect(() => {
      if (currentUser.value?.id) {
        const qs = new QueryString(
          router.currentRoute.value.params.nextPageQueryString as string,
        );

        router.push({
          name: publicPreviewRouteNames.publicPreview,
          query: qs.toJSON(),
        });
      }
    });

    const { call, isLoading } = usePasswordAuth(
      new QueryString(
        router.currentRoute.value.params.nextPageQueryString as string,
      ),
    );

    const handleLogin = () => {
      call(password.value);
    };

    return {
      password,
      isLoading,
      handleLogin,
      showPasswordWarning,
    };
  },
};
