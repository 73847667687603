import { Commit } from 'vuex';
import { Motive } from '@/features/campaigns/domain/motive/motive';
import { MotiveDTO } from '@/features/campaigns/infrastructure/motive_dto';
import { FormatRawDTO } from '@/features/campaigns/infrastructure/format_raw_dto';
import { FormatDefaultDTO } from '@/features/campaigns/infrastructure/format_default_dto';
import { FormatRaw } from '@/features/campaigns/domain/format/formatRaw';
import { FormatDefault } from '@/features/campaigns/domain/format/formatDefault';

export interface PublicPreview {
  campaignId: string;
  campaignName: string;
  motives: {
    motiveId: string;
    format: Record<string, any>;
    formatDefaultInstance: Record<string, any>;
  }[];
}

export interface PublicPreviewTransformed {
  campaignId: string;
  campaignName: string;
  motives: {
    motiveId: string;
    format: FormatRaw;
    formatDefaultInstance: FormatDefault;
  }[];
}

interface PublicPreviewStoreState {
  publicPreviews: PublicPreview[];
  motives: Record<string, any>[];
}

export const publicPreviewMutationKeys = {
  fetchPublicPreview: 'fetchPublicPreview',
  fetchMotives: 'fetchMotives',
};

export const publicPreviewActionKeys = {
  setPublicPreviews: 'setPublicPreviews',
  setMotives: 'setMotives',
};

export const initialState: PublicPreviewStoreState = {
  publicPreviews: [],
  motives: [],
};

export const getters = {
  getPreviews: (state: PublicPreviewStoreState) => () => {
    if (!state.publicPreviews || state.publicPreviews.length === 0) return [];

    return state.publicPreviews.map(preview => ({
      ...preview,
      motives: preview.motives.map(motive => ({
        motiveId: motive.motiveId,
        format: FormatRawDTO.toDomain(motive.format),
        formatDefaultInstance: FormatDefaultDTO.toDomain(
          motive.formatDefaultInstance,
        ),
      })),
    }));
  },
  getPublicPreviewMotives: (state: PublicPreviewStoreState) => () => {
    if (!state.motives || state.motives.length === 0) return [];

    return state.motives.map(motive => MotiveDTO.toDomain(motive));
  },
  getPreviewMotivesIds: (state: PublicPreviewStoreState) => () => {
    if (!state.publicPreviews || state.publicPreviews.length === 0) return [];

    const result = state.publicPreviews.map(preview =>
      preview.motives.map(motive => motive.motiveId),
    );

    return result.flat();
  },
  getPublicPreviewMotiveById: (state: PublicPreviewStoreState) => (
    id: string,
  ) => {
    if (!state.motives || state.motives.length === 0) return;

    const found = state.motives.find(motive => motive.id === id);

    if (found) {
      return MotiveDTO.toDomain(found);
    }
  },
};

const mutations = {
  [publicPreviewMutationKeys.fetchPublicPreview](
    state: PublicPreviewStoreState,
    args: { publicPreviews: PublicPreview[] },
  ) {
    state.publicPreviews = args.publicPreviews;
  },
  [publicPreviewMutationKeys.fetchMotives](
    state: PublicPreviewStoreState,
    args: { motives: Motive[] },
  ) {
    state.motives = args.motives.map(motive => MotiveDTO.toJson(motive));
  },
};

const actions = {
  [publicPreviewActionKeys.setPublicPreviews](
    { commit }: { commit: Commit },
    args: { publicPreviews: PublicPreview[] },
  ) {
    commit(publicPreviewMutationKeys.fetchPublicPreview, args);
  },
  [publicPreviewActionKeys.setMotives](
    { commit }: { commit: Commit },
    args: { motives: Motive[] },
  ) {
    commit(publicPreviewMutationKeys.fetchMotives, args);
  },
};

export default {
  state: initialState,
  getters,
  mutations,
  actions,
};
